import { v4 as uuidv4 } from "uuid";

export const addUniqueId = (list, childrenKeyName, parents = []) => {
  list.forEach(item => {
    item.UUIDParents = [...parents];

    const newUUID = uuidv4();
    item.UUID = newUUID;
    if (item[childrenKeyName]) {
      item.expanded = true;
      addUniqueId(item[childrenKeyName], childrenKeyName, [
        ...item.UUIDParents,
        newUUID
      ]);
    }
  });
};

export const rowObjectToArray = obj =>
  Object.keys(obj)
    .sort((a, b) => {
      return Number(a.split("row")[1]) - Number(b.split("row")[1]);
    })
    .map(i => obj[i]);

export const splitDashboardTags = tagString =>
  tagString.split(",").map(t => t.trim());

export const getBlob = (Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getValuesByKey = (data, key) => {
  return data
    .filter(d => d.propKey === key)[0]
    .value.split(",")
    .map(d => {
      return { value: d, label: d };
    });
};

export const getValueOnlyByKey = (data, key) => {
  return data.filter(d => d.propKey === key)[0].value;
};

export const getUrlWithNoSpace = url =>
  url
    ? url
        .replace(/ - /g, "-")
        .replace(/\s/g, "-")
        .replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/gi, "")
        .toLowerCase()
    : "dashboard";

export const dateformat = d => {
  var date = new Date(`${d} 00:00:00`);
  date =
    date.toLocaleDateString(undefined, { month: "long" }) +
    " " +
    date.toLocaleDateString(undefined, { day: "2-digit" }) +
    ", " +
    date.toLocaleDateString(undefined, { year: "numeric" });
  return d ? date : "";
};

export const getQuarterQuery = () => {
  const d = new Date();
  let month = d.getMonth(),
    year = d.getFullYear();
  const quarter = Math.floor(month / 3);
  const qurterQuery = !quarter ? `Q4 ${year - 1}` : `Q${quarter} ${year}`;
  return qurterQuery.toLowerCase();
};

export const capitalize = (p = "") =>
  p
    .split(" ")
    .map(p => p.charAt(0).toUpperCase() + p.slice(1))
    .join(" ");

export const getPercentage = (a = 0, b = 1) => {
  let percentage = (a / b) * 100;
  return !!percentage ? percentage.toFixed(1) : 0;
};

export const convertTo_M_B_K = num => {
  // Nine Zeroes for Billions
  return Math.abs(Number(num)) >= 1.0e9
    ? (Math.abs(Number(num)) / 1.0e9).toFixed(1) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(num)) >= 1.0e6
    ? (Math.abs(Number(num)) / 1.0e6).toFixed(1) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(num)) >= 1.0e3
    ? (Math.abs(Number(num)) / 1.0e3).toFixed(1) + "K"
    : Math.abs(Number(num));
};

export const convertTo_M = num => {
  if (num == 0) {
    return "NA";
  }
  return (Math.abs(Number(num)) / 1.0e6).toFixed(1) + "M";
};

export const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getUniqueId = () => uuidv4();
