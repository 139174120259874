import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import {
  fetchEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  trackUserAction
} from "../../services/dataService";
import Grid from "../../components/Grid";
import AccentButton from "../../components/AccentButton";
import { useAlertsContext } from "../../context/alerts";

import "./Events.scss";
import Spinner from "../../components/Spinner";
import ModalCard from "../../components/ModalCard";
import Select from "react-select";

function datetimeLocal() {
  var date = new Date();
  date = new Date(date.setMinutes(date.getMinutes() + 5));
  var ten = function(i) {
      return (i < 10 ? "0" : "") + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes());
  return YYYY + "-" + MM + "-" + DD + "T" + HH + ":" + II;
}

function endDateLocal(dt) {
  var date = !!dt ? new Date(dt) : new Date();
  date = new Date(date.setDate(date.getDate() + 1));
  var ten = function(i) {
      return (i < 10 ? "0" : "") + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate());
  return YYYY + "-" + MM + "-" + DD;
}

const HOURS = [
  { value: 0, label: "00" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" }
];
const MINUTES = [
  { value: 0, label: "00" },
  { value: 15, label: "15" },
  { value: 30, label: "30" },
  { value: 45, label: "45" }
];

function Events(props) {
  const [show, setShow] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, errors, reset, getValues } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [delId, setDelId] = useState(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const [duration, setDuration] = useState(60);
  const [hours, setHours] = useState(Math.floor(duration / 60));
  const [minutes, setMinutes] = useState(Math.floor(duration % 60));
  const [minEndDate, setMinEndDate] = useState(endDateLocal());

  const handleCloseDelModal = () => {
    setDelId(null);
  };
  const handleShowDelModal = id => {
    setDelId(id);
  };
  const handleRecurrenceCheck = e => {
    setIsRecurring(e.target.checked);
  };

  useEffect(() => {
    fetchEvents()
      .then(res => {
        setEvents(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Events",
          targetPath: "/admin/events",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setDuration(hours * 60 + minutes);
  }, [hours, minutes]);

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setShow(false);
    setIsRecurring(false);
    setHours(1);
    setMinutes(0);
  };

  const handleNewEventClick = () => {
    setShow(true);
  };

  const onSubmit = async data => {
    const eventID = itemForEdit ? itemForEdit.eventID : null;
    const isEdit = !!eventID;
    try {
      const {
        data: [response]
      } = isEdit
        ? await updateEvent({
            ...data,
            // Format date string according to DB requirement YYYY-MM-DD HH:MM:SS
            eventStartDate: data.eventStartDate?.split("T").join(" "),
            endDate: data.endDate?.split("T").join(" "),
            isRecurring,
            duration,
            eventID: eventID
          })
        : await createEvent({
            ...data,
            // Format date string according to DB requirement YYYY-MM-DD HH:MM:SS
            eventStartDate: data.eventStartDate?.split("T").join(" "),
            endDate: data.endDate?.split("T").join(" "),
            isRecurring,
            duration
          });
      await trackUserAction({
        action: isEdit ? "Edit" : "Create",
        targetType: "Admin",
        targetName: "Events",
        targetPath: "/admin/events",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      handleCloseModal();
      addSuccessAlert(`Event ${isEdit ? "updated" : "created"}`);

      if (isEdit) {
        const index = events.findIndex(i => i.eventID === response.eventID);
        if (index !== undefined) {
          // Insert new updated row into the grid at the correct index
          setEvents(items =>
            items.map((i, idx) => {
              if (idx === index) {
                return response;
              }
              return i;
            })
          );
        }
      } else {
        // Add new row to the end
        setEvents([...events, response]);
      }
    } catch (err) {
      handleCloseModal();
      addFailAlert("Something went wrong");
    }
  };

  const handleDeleteClick = async eventID => {
    try {
      await deleteEvent(eventID);
      await trackUserAction({
        action: "Delete",
        targetType: "Admin",
        targetName: "Events",
        targetPath: "/admin/events",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("Event deleted");
      setEvents(items => items.filter(i => i.eventID !== eventID));
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleEditClick = event => {
    console.log(event);
    setItemForEdit(event);
    reset({
      ...event,
      eventStartDate: event.eventStartDate?.split(" ").join("T"),
      endDate: event.endDate?.split(" ")[0]
    });
    setIsRecurring(event.isRecurring);
    setHours(Math.floor(event.duration / 60));
    setMinutes(Math.floor(event.duration % 60));
    setMinEndDate(endDateLocal(event.eventStartDate?.split(" ").join("T")));
    setShow(true);
  };

  return (
    <div className="Events">
      <Button className="mb-3" onClick={handleNewEventClick}>
        <AiOutlinePlus /> New Event
      </Button>

      {loading ? (
        <Spinner>Fetching Events</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Events"
          columnDefs={[
            {
              field: "eventTitle",
              headerName: "Event",
              sortable: true,
              resizable: true
            },
            {
              field: "eventStartDate",
              headerName: "When",
              sortable: true,
              resizable: true
            },
            {
              field: "eventDetails",
              headerName: "Details",
              sortable: true,
              resizable: true
            },
            {
              field: "registrationURL",
              headerName: "Registration",
              sortable: true,
              resizable: true
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton onClick={() => handleEditClick(data)}>
                      <AiOutlineEdit /> Edit
                    </AccentButton>
                    <AccentButton
                      className="sx-accent-button--danger ml-2"
                      onClick={() => handleShowDelModal(data.eventID)}
                    >
                      <AiOutlineDelete /> Delete
                    </AccentButton>
                  </>
                );
              }
            }
          ]}
          rowData={events}
        />
      )}

      <Modal className="events-modal" show={show} onHide={handleCloseModal}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {!!itemForEdit ? "Edit" : "Add New"} Event
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group as={Row} controlId="events-title">
              <Form.Label column sm="2">
                Title*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="eventTitle"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.eventTitle}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="events-description">
              <Form.Label column sm="2">
                Details*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="textarea"
                  name="eventDetails"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.eventDetails}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="events-url">
              <Form.Label column sm="2">
                URL*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="registrationURL"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.registrationURL}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="events-start-date">
              <Form.Label column sm="2">
                When*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="eventStartDate"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="datetime-local"
                  min={datetimeLocal()}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                  defaultValue={datetimeLocal()}
                  isInvalid={!!errors.eventStartDate}
                  onChange={e =>
                    setMinEndDate(endDateLocal(getValues("eventStartDate")))
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="events-duration">
              <Form.Label column sm="2">
                Duration*
              </Form.Label>
              <Col sm="3">
                <Select
                  name="hours"
                  options={HOURS}
                  placeholder="Hours"
                  value={HOURS.find(item => item.value === hours)}
                  onChange={e => setHours(+e.value)}
                />
              </Col>
              <Form.Label column sm="1" className="duration-label">
                hr
              </Form.Label>
              <Col sm="3">
                <Select
                  name="minutes"
                  options={MINUTES}
                  placeholder="Minutes"
                  value={MINUTES.find(item => item.value === minutes)}
                  onChange={e => setMinutes(+e.value)}
                />
              </Col>
              <Form.Label column sm="1" className="duration-label">
                mins
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} controlId="isRecurring">
              <Col sm={{ span: 10, offset: 2 }}>
                <Form.Check
                  checked={isRecurring}
                  type="checkbox"
                  label="Recurring Webinar"
                  ref={register({ name: "isRecurring" })}
                  onChange={handleRecurrenceCheck}
                />
              </Col>
            </Form.Group>
            {!!isRecurring && (
              <Form.Group as={Row} controlId="events-end-date">
                <Form.Label column sm="2">
                  End Date*
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="endDate"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="date"
                    min={minEndDate}
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    defaultValue={minEndDate}
                    isInvalid={!!errors.endDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this ${`Event`}?`}
          title={`Delete ${`Event`} Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={handleCloseDelModal}
          handleYes={() => {
            handleDeleteClick(delId);
            handleCloseDelModal();
          }}
        />
      )}
    </div>
  );
}

export default Events;
