import React from "react";
import useSessionManager from "../../hooks/sessionManager";
import useAuthManager from "../../hooks/useAuthManager";
import jwtDecode from "jwt-decode";
import SessionModals from "./SessionModals";
import AppLoader from "./AppLoader";
import AppError from "./AppError";

function AuthSessionWrapper({ renderApp }) {
  const {
    userName,
    isLoading,
    getTokensByRefreshToken,
    isExecutiveUser
  } = useAuthManager();
  const { timeLeft, isWarning, isSessionExpired, reset } = useSessionManager({
    sessionTimeSeconds: 60 * 30, // half hour
    warningTimeSeconds: 1 * 60, // 1 minute
    throttleMs: 5 * 60 * 1000, // 5 minutes
    actionFn: () => {
      const idToken = localStorage.getItem("ID_TOKEN");

      // Check if token will expire in the next 5 minutes
      if (
        !idToken ||
        jwtDecode(idToken).exp * 1000 - Date.now() <= 1000 * 60 * 5
      ) {
        getTokensByRefreshToken(localStorage.getItem("REFRESH_TOKEN"));
      }
    }
  });

  return (
    <>
      <SessionModals
        isSessionExpired={isSessionExpired}
        isWarning={isWarning}
        timeLeft={timeLeft}
        reset={reset}
      />

      {isLoading && <AppLoader />}

      {!isLoading && !userName && <AppError />}

      {!isLoading &&
        userName &&
        renderApp(userName, isSessionExpired, isExecutiveUser)}
    </>
  );
}

export default AuthSessionWrapper;
