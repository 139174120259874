import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Controller, useForm } from "react-hook-form";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineSave
} from "react-icons/ai";
import Select, { components } from "react-select";
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
import Grid from "../../components/Grid";
import ModalCard from "../../components/ModalCard";
import Spinner from "../../components/Spinner";
import TreeView from "../../components/TreeView/_index";
import { useAlertsContext } from "../../context/alerts";
import {
  createCategory,
  deleteCategory,
  fetchAllCategories,
  fetchAppVariables,
  fetchContents,
  fetchUsers,
  saveSortOrder,
  trackUserAction,
  updateCategory
} from "../../services/dataService";
import {
  addUniqueId,
  capitalize,
  getValueOnlyByKey,
  getValuesByKey
} from "../../services/utils";
import "./AppVariables.scss";

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement(props => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return (
    <components.MultiValue
      className="sortable-element"
      {...props}
      innerProps={innerProps}
    />
  );
});

const SortableMultiValueLabel = sortableHandle(props => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

function MultiSelectSort({ value, options, onOptionsChange }) {
  const [selected, setSelected] = React.useState(value);

  const onChange = selectedOptions => setSelected(selectedOptions);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
  };

  useEffect(() => {
    onOptionsChange(selected);
  }, [onOptionsChange, selected]);

  return (
    <SortableSelect
      useDragHandle
      helperClass="sortable-z-index"
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={options}
      value={selected}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel
      }}
      closeMenuOnSelect={false}
    />
  );
}
const VISIBLE = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" }
];

const invalidStyle = {
  placeholder: base => ({
    ...base,
    fontSize: "1em",
    color: "#dc3545",
    fontWeight: 400
  }),
  container: base => ({
    ...base,
    backgroundColor: "#dc3545",
    padding: 0.5
  })
};
const DEFAULT_SELECT_VALUES = [{ value: "", label: "" }];
let layoutValues = DEFAULT_SELECT_VALUES;

function CategoryManagement(props) {
  const [show, setShow] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [isCategory, setIsCategory] = useState(true);
  const [selectedVisible, setSelectedVisible] = useState(VISIBLE[0]);
  const [selectedType, setSelectedType] = useState([]);
  const [hrefValue, setHrefValue] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState([]);
  const [typeValues, setTypeValues] = useState([]);
  const [selectedTreeItem, setSelectedTreeItem] = useState(null);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    setValue,
    clearErrors,
    control
  } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [delId, setDelId] = useState(null);

  const [folders, setFolders] = useState([]);

  const [selectedCategoryToSort, setSelectedCategoryToSort] = useState(null);
  const [categoriesToSort, setCategoriesToSort] = useState([]);

  const handleCloseDelModal = () => {
    setDelId(null);
  };
  const handleShowDelModal = id => {
    setDelId(id);
  };

  const refreshCategories = useCallback(() => {
    fetchAllCategories()
      .then(res => {
        setAllCategories(res.data.sort((a, b) => a.sortOrder - b.sortOrder));
        setCategories(res.data.filter(c => c.parentId === -1));
        let hrefs = [...new Set(res.data.map(el => el.href))];

        let formattedData = formatTreeViewData(res.data);

        formattedData = hrefs.map((el, index) => {
          return {
            name: capitalize(el === "asdashboard" ? "as dashboard" : el),
            nodeType: "Group",
            children: [...formattedData.filter(item => item.href === el)]
          };
        });

        addUniqueId(formattedData, "children");

        setFolders(formattedData);

        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Categories",
          targetPath: "/admin/categories",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    refreshCategories();
    fetchUsers().then(res =>
      setUsers(
        res.data
          .filter(el => el.userStatus === "A" && el.userType !== "E")
          .map(u => ({ label: u.fullName, value: u.userNTId }))
      )
    );
  }, [refreshCategories]);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }
    setSubCategories(
      allCategories
        .filter(c => c.parentId === selectedCategory.id)
        .sort((a, b) => a.sortOrder > b.sortOrder)
    );
    // if (isCategory) {
    setSelectedCategory(
      allCategories.filter(c => c.id === selectedCategory.id)[0]
    );
    // } else {
    if (!selectedSubCategory) {
      return;
    }
    setSelectedSubCategory(
      allCategories.filter(c => c.id === selectedSubCategory.id)[0]
    );
    // }

    return () => {
      setSubCategories([]);
      setSelectedCategory(null);
      setSelectedSubCategory(null);
    };
  }, [allCategories, isCategory, selectedCategory, selectedSubCategory]);

  useEffect(() => {
    fetchAppVariables().then(res => {
      layoutValues = getValuesByKey(res.data, "APP_UI_LAYOUT_TYPES");
      setSelectedLayout(layoutValues[0]);
      const types = JSON.parse(
        getValueOnlyByKey(res.data, "APP_UI_CATEGORY_TYPES")
      );

      setTypeValues(types);
      setSelectedType(types.filter(v => v.value === "marketplace")[0]);
    });
  }, []);

  useEffect(() => {
    if (!!selectedDashboards?.length) {
      clearErrors("contentList");
    }
  }, [selectedDashboards]);

  const handleSelectLayout = selectedLayout => {
    setValue("layout", selectedLayout);
    setSelectedLayout(selectedLayout);
  };

  const handleCloseModal = () => {
    setSelectedDashboards(prev => prev?.splice(0, prev.length));
    setItemForEdit(null);
    reset({});
    setShow(false);
    setSelectedVisible(VISIBLE[0]);
    setSelectedType(typeValues.filter(v => v.value === "marketplace")[0]);
    setHrefValue("");
    setSelectedLayout(layoutValues[0]);
    // setSelectedSubCategory(null);
    clearErrors("contentList");
    // isCategory && setSelectedCategory(null);
  };

  const loadContent = useCallback(() => {
    fetchContents().then(res =>
      setDashboards(
        res.data.map(d => {
          return {
            label: `${d.name} ${d.filters ? `(${d.filters})` : ""}`,
            value: d.id
          };
        })
      )
    );
  }, []);

  const handleNewCategoryClick = () => {
    setSelectedCategory(null);
    setSubCategories([]);
    setIsCategory(true);
    loadContent();
    setShow(true);
  };

  const handleNewSubCategoryClick = () => {
    setSelectedSubCategory(null);
    setIsCategory(false);
    loadContent();
    setShow(true);
  };

  const handleEditClick = isCat => {
    setIsCategory(isCat);
    let catType = isCat ? selectedCategory : selectedSubCategory;
    setItemForEdit(catType);
    reset(catType);
    loadContent();
    setShow(true);
    setSelectedVisible(VISIBLE.filter(v => v.value === catType.isVisible)[0]);

    if (typeValues.map(el => el.value).includes(catType.href)) {
      setSelectedType(typeValues.filter(v => v.value === catType.href)[0]);
      setHrefValue(catType.href);
    } else {
      setSelectedType(typeValues.filter(v => v.value === "marketplace")[0]);
      setHrefValue("");
    }
    setSelectedLayout(layoutValues.filter(v => v.value === catType.layout)[0]);

    if (
      catType.Dashboards.length === 1 &&
      !catType.Dashboards[0].DashboardID &&
      !catType.Dashboards[0].DashboardName
    ) {
      setSelectedDashboards([]);
    } else {
      setSelectedDashboards(
        catType.Dashboards.sort((a, b) => a.dbSortOrder - b.dbSortOrder).map(
          g => {
            return { value: g.DashboardID, label: g.DashboardName };
          }
        )
      );
    }
  };

  const handleSelectVisible = selectedVisible => {
    setValue("isVisible", selectedVisible);
    setSelectedVisible(selectedVisible);
  };

  const handleSelectType = selectedType => {
    setValue("href", selectedType);
    setSelectedType(selectedType);
  };

  const handleHrefValue = e => {
    setHrefValue(e.target.value);
  };

  const handleSelectDashboards = selectedDashboards => {
    setValue("contentList", selectedDashboards);
    setSelectedDashboards(selectedDashboards);
  };

  const onSubmit = async data => {
    const itemID = itemForEdit ? itemForEdit.id : null;
    const isEdit = !!itemID;

    let owners = [];

    if (isCategory) {
      owners[0] = { userId: data.productOwner.value };
      // if (!selectedDashboards.length && !selectedTreeItem.children.length) {
      //   setError("contentList", {
      //     type: "custom",
      //     message: "Atleast one dashboard should be associated."
      //   });
      //   return;
      // }
    } else {
      owners[0] = { userId: data.primaryOwner.value, isPrimary: "true" };
      if (data.secondaryOwner?.value) {
        owners[1] = { userId: data.secondaryOwner.value, isPrimary: "false" };
      }
      let foundSubCategory = subCategories.find(
        el => el.name.toLowerCase() === data.name.toLowerCase()
      );
      if (foundSubCategory && !isEdit) {
        setError("name", {
          type: "custom",
          message: "Subcategory should be unique in a category."
        });
        return;
      }
      // if (!selectedDashboards.length && !selectedTreeItem.children.length) {
      //   setError("contentList", {
      //     type: "custom",
      //     message: "Atleast one dashboard should be associated."
      //   });
      //   return;
      // }
    }

    try {
      if (isEdit) {
        await updateCategory({
          ...data,
          layout: isCategory
            ? selectedLayout?.value || "Cards"
            : selectedCategory.layout,
          id: isCategory ? selectedCategory.id : selectedSubCategory.id,
          parentId: isCategory ? -1 : selectedCategory.id,
          isVisible: selectedVisible.value,
          href: isCategory
            ? selectedType?.value || "marketplace"
            : selectedCategory.href,
          contentList: selectedDashboards
            ? selectedDashboards.map(s => s.value).join()
            : "",
          owners: owners
        });
        await trackUserAction({
          action: "Edit",
          targetType: "Admin",
          targetName: isCategory ? "Categories" : "SubCategories",
          targetPath: "/admin/categories",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      } else {
        await createCategory({
          ...data,
          layout: isCategory
            ? selectedLayout?.value || "Cards"
            : selectedCategory.layout,
          parentId: isCategory ? -1 : selectedTreeItem.id,
          isVisible: selectedVisible.value,
          href: isCategory
            ? selectedType?.value || "marketplace"
            : selectedCategory.href,
          contentList: selectedDashboards
            ? selectedDashboards.map(s => s.value).join()
            : "",

          level: isCategory ? 1 : selectedTreeItem.level + 1,
          owners: owners
        });
        await trackUserAction({
          action: "Create",
          targetType: "Admin",
          targetName: isCategory ? "Categories" : "SubCategories",
          targetPath: "/admin/categories",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      }
      handleCloseModal();
      refreshCategories();
      addSuccessAlert(`Category Level ${isEdit ? "updated" : "created"}`);
      setSelectedCategory(null);
      setSelectedCategoryToSort(null);
      setCategoriesToSort([]);
    } catch (err) {
      handleCloseModal();
      addFailAlert("Something went wrong");
    }
  };

  const handleDeleteClick = async id => {
    try {
      await deleteCategory({
        id: id
      });
      await trackUserAction({
        action: "Delete",
        targetType: "Admin",
        targetName: isCategory ? "Categories" : "SubCategories",
        targetPath: "/admin/categories",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert(` Category deleted`);
      setSelectedCategoryToSort(null);
      refreshCategories();
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleSaveClick = async isCat => {
    const idLists = categoriesToSort.map(c => c.id);
    try {
      await saveSortOrder({
        categoriesList: idLists.join()
      });
      await trackUserAction({
        action: "Save Sort Order",
        targetType: "Admin",
        targetName: isCat ? "Categories" : "SubCategories",
        targetPath: "/admin/categories",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert(`Category sort order updated`);
      setSelectedCategoryToSort(null);
      setCategoriesToSort([]);
      refreshCategories();
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const array_move = (arr, element, move) => {
    let old_index = arr.indexOf(element);
    let new_index = old_index + move;
    if (0 <= new_index && new_index <= arr.length) {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }
    return arr;
  };

  const handleUpCat = () => {
    console.log(array_move(categoriesToSort, selectedCategoryToSort, -1));
  };

  const handleDownCat = () => {
    console.log(array_move(categoriesToSort, selectedCategoryToSort, 1));
  };

  const handleUpSubCat = () => {
    setSubCategories(array_move(subCategories, selectedSubCategory, -1));
  };

  const handleDownSubCat = () => {
    setSubCategories(array_move(subCategories, selectedSubCategory, 1));
  };

  const formatTreeViewData = (items, id = -1, link = "parentId") =>
    items
      .filter(item => item[link] === id)
      .map(item => ({ ...item, children: formatTreeViewData(items, item.id) }));

  function lookForFolder(list, item) {
    list.forEach(node => {
      if (node.UUID === item.UUID) {
        node.expanded = !node.expanded;
      }
      if (node.children) {
        lookForFolder(node.children, item);
      }
    });
  }

  const handleExpandTreeClick = item => {
    if (item.nodeType === "Group") {
      lookForFolder(folders, item);
      setFolders(p => [...p]);
      return;
    }
    // !item.expanded ?  : setSelectedTreeItem(null);
    setSelectedTreeItem(item);
    lookForFolder(folders, item);
    setFolders(p => [...p]);
    setSelectedCategory(item);
    let listTosort = null;
    let checkForLevel1Parent = null;
    if (item.parentId === -1) {
      setIsCategory(true);
      checkSortParent(folders, item.UUIDParents[0]);
      setSelectedCategory(item);
    } else {
      setIsCategory(false);
      checkSortParent(folders, item.UUIDParents[item.level - 1]);
      checkLevel1Parent(folders, item.UUIDParents[1]);
      setSelectedCategory(checkForLevel1Parent);
    }
    setSelectedSubCategory(item);
    setSelectedCategoryToSort(item);
    setCategoriesToSort(listTosort.children);

    function checkSortParent(o, l) {
      o.forEach(o => {
        if (o.UUID === l) listTosort = o;
        o.children && checkSortParent(o.children, l);
      });
    }
    function checkLevel1Parent(o, l) {
      o.forEach(o => {
        if (o.UUID === l) checkForLevel1Parent = o;
        o.children && checkLevel1Parent(o.children, l);
      });
    }
  };
  return (
    <div className="CategoryManagement">
      {loading ? (
        <Spinner>Fetching Category Levels</Spinner>
      ) : (
        <div>
          <div style={{ maxHeight: "50rem", overflow: "auto" }}></div>
          <Row>
            <Col xs={5}>
              <h5>Category Levels</h5>
              <Button className="mb-3 mx-2" onClick={handleNewCategoryClick}>
                <AiOutlinePlus /> New
              </Button>
              <Button
                className="mb-3 mx-2"
                onClick={() => handleSaveClick()}
                disabled={!selectedCategoryToSort}
              >
                <AiOutlineSave /> Save Sort Order
              </Button>
              <div className="categories-table">
                <TreeView
                  className="mt-4 ml-4"
                  selectedTreeItem={selectedTreeItem}
                  onNodeClick={handleExpandTreeClick}
                  renderLabel={item => (
                    <>
                      {item.name}{" "}
                      {selectedTreeItem?.UUID === item.UUID && (
                        <span className="float-right">
                          <AiOutlinePlus
                            className="mr-3 "
                            style={{ fontSize: 20 }}
                            onClick={e => {
                              e.stopPropagation();
                              handleNewSubCategoryClick();
                            }}
                          />
                          <AiOutlineEdit
                            className="mr-3 "
                            style={{ fontSize: 20 }}
                            onClick={e => {
                              e.stopPropagation();
                              selectedTreeItem?.parentId === -1
                                ? handleEditClick(true)
                                : handleEditClick(false);
                            }}
                          />{" "}
                          <AiOutlineDelete
                            className="mr-3 "
                            style={{ fontSize: 20 }}
                            onClick={e => {
                              e.stopPropagation();
                              setIsCategory(true);
                              handleShowDelModal(selectedTreeItem.id);
                            }}
                          />
                        </span>
                      )}
                    </>
                  )}
                  childrenKeyName="children"
                  nameField="name"
                  renderChild={item => (
                    <div className="d-flex align-items-center justify-content-between">
                      {/* <Button onClick={() => {}} variant="link">
                        <span className="pr-2 text-primary">
                          {reportTypeIconMap[item.outputFormat]}
                        </span>
                        {item.name}
                      </Button> */}
                    </div>
                  )}
                  searchTerm={""}
                  data={folders}
                />
              </div>
            </Col>
            <Col
              xs={1}
              className="d-flex align-items-start justify-content-center flex-column ml-3"
            >
              <Row>
                <Button
                  className="mb-3"
                  onClick={handleUpCat}
                  disabled={!selectedCategoryToSort}
                >
                  <AiOutlineCaretUp />
                </Button>
              </Row>
              <Row>
                <Button
                  className="mb-3"
                  onClick={handleDownCat}
                  disabled={!selectedCategoryToSort}
                >
                  <AiOutlineCaretDown />
                </Button>
              </Row>
            </Col>
            <Col xs={6} className="mt-5 pt-2 ml-n4 mr-n4">
              {selectedSubCategory && (
                <Row className="mt-4 ml-n5">
                  <Grid
                    width="100%"
                    title="Categories Data"
                    suppressRowTransform={true}
                    getRowHeight={params => {
                      return (
                        params.api.getSizesForCurrentTheme().rowHeight *
                        params.data.Dashboards.length
                      );
                    }}
                    columnDefs={[
                      {
                        field: "name",
                        headerName: "Name",
                        sortable: true,
                        resizable: true
                      },
                      {
                        field: "description",
                        headerName: "Description",
                        sortable: true,
                        resizable: true
                      },
                      {
                        field: "sortOrder",
                        headerName: "Sort Order",
                        sortable: true,
                        resizable: true
                      },
                      {
                        field: "isVisible",
                        headerName: "Show on InSXight",
                        sortable: true,
                        resizable: true,
                        cellRendererFramework: ({ value }) =>
                          VISIBLE.filter(v => v.value === (value || "N"))[0]
                            ?.label
                      },
                      {
                        field: "Dashboards",
                        headerName: "Category Objects",
                        sortable: true,
                        resizable: true,
                        cellRendererFramework: ({ value }) => {
                          return (
                            <>
                              {value
                                .sort((a, b) => a.dbSortOrder - b.dbSortOrder)
                                .map(v => {
                                  return (
                                    <div key={v.DashboardID}>
                                      {v.DashboardName}
                                    </div>
                                  );
                                })}
                            </>
                          );
                        }
                      }
                    ]}
                    rowData={[selectedSubCategory]}
                  />
                </Row>
              )}
            </Col>
          </Row>
        </div>
      )}

      <Modal
        className="categories-modal"
        show={show}
        onHide={handleCloseModal}
        size="xl"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {`${!!itemForEdit ? "Edit" : "Add New"}  Category Level`}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col>
                <Form.Group as={Row} controlId="categories-name">
                  <Form.Label column sm="4">
                    Name*
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="name"
                      ref={register({
                        required: true,
                        validate: val => val.trim() !== ""
                      })}
                      type="text"
                      placeholder=""
                      isInvalid={!!errors.name}
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.name ? errors.name.message : "Required"}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="categories-description">
                  <Form.Label column sm="4">
                    Description
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      name="description"
                      ref={register({
                        setValue: val => val.trim()
                      })}
                      type="text"
                      placeholder=""
                      maxLength={4000}
                      rows={isCategory ? 3 : 4}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="categories-isVisible">
                  <Form.Label column sm="4">
                    Show on InSXight
                  </Form.Label>
                  <Col sm="8">
                    <Select
                      value={selectedVisible}
                      name="isVisible"
                      options={VISIBLE}
                      onChange={handleSelectVisible}
                      ref={register({ name: "isVisible" })}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {isCategory ? (
                  <>
                    <Form.Group as={Row} controlId="categories-layout">
                      <Form.Label column sm="4">
                        Layout *
                      </Form.Label>
                      <Col sm="8">
                        <Select
                          value={selectedLayout}
                          defaultValue={layoutValues[0]}
                          name="layout"
                          options={layoutValues}
                          onChange={handleSelectLayout}
                          ref={register({ name: "layout" })}
                        />
                        <Form.Control.Feedback type="invalid">
                          Required
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    {selectedType.value === "href" && (
                      <Form.Group as={Row} controlId="categories-href-value">
                        <Form.Label column sm="4">
                          Href
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            name="href"
                            ref={register({
                              setValueAs: val => val.trim()
                            })}
                            type="text"
                            onChange={handleHrefValue}
                            value={hrefValue}
                            placeholder=""
                            maxLength={250}
                          />
                        </Col>
                      </Form.Group>
                    )}
                  </>
                ) : (
                  <>
                    <Form.Group as={Row} controlId="categories-layout">
                      <Form.Label column sm="4">
                        Layout *
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="layout"
                          type="text"
                          disabled
                          defaultValue={selectedCategory?.layout}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="categories-href">
                      <Form.Label column sm="4">
                        Type *
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="href"
                          type="text"
                          disabled
                          defaultValue={
                            typeValues?.find(
                              e => e.value === selectedCategory?.href
                            )?.label
                          }
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Col>
              <Col>
                <Form.Group as={Row} controlId="categories-filter">
                  <Form.Label column sm="4">
                    Filter
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="filter"
                      ref={register({
                        setValueAs: val => val.trim()
                      })}
                      type="text"
                      placeholder=""
                      maxLength={250}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="categories-header">
                  <Form.Label column sm="4">
                    Header
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="header"
                      ref={register({
                        setValue: val => val.trim()
                      })}
                      type="text"
                      placeholder=""
                      maxLength={250}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="categories-imagePath">
                  <Form.Label column sm="4">
                    Image
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="imagePath"
                      ref={register({
                        setValueAs: val => val.trim()
                      })}
                      type="text"
                      placeholder=""
                      maxLength={250}
                    />
                  </Col>
                </Form.Group>

                {isCategory ? (
                  <>
                    <Form.Group as={Row} controlId="categories-productOwner">
                      <Form.Label column sm="4">
                        EDO Product Owner *
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          control={control}
                          name="productOwner"
                          as={Select}
                          defaultValue={users.find(
                            u => u.value === selectedCategory?.owners[0]?.userId
                          )}
                          options={users}
                          rules={{ required: true }}
                          isInvalid={errors.productOwner}
                          placeholder={
                            errors.productOwner ? `Required` : `Select...`
                          }
                          styles={errors.productOwner ? invalidStyle : {}}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="categories-href">
                      <Form.Label column sm="4">
                        Type *
                      </Form.Label>
                      <Col sm="8">
                        <Select
                          value={selectedType}
                          name="href"
                          options={typeValues}
                          onChange={handleSelectType}
                          ref={register({ name: "href" })}
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group as={Row} controlId="categories-productOwner">
                      <Form.Label column sm="4">
                        EDO Product Owner *
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="productOwner"
                          type="text"
                          disabled
                          defaultValue={
                            users.find(
                              u =>
                                u.value === selectedCategory?.owners[0]?.userId
                            )?.label
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="categories-primaryOwner">
                      <Form.Label column sm="4">
                        Primary Business Owner *
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          control={control}
                          name="primaryOwner"
                          as={Select}
                          defaultValue={users.find(
                            u =>
                              u.value ===
                              selectedSubCategory?.owners.find(
                                o => o.isPrimary === "true"
                              )?.userId
                          )}
                          options={users}
                          rules={{ required: true }}
                          isInvalid={errors.primaryOwner}
                          placeholder={
                            errors.primaryOwner ? `Required` : `Select...`
                          }
                          styles={errors.primaryOwner ? invalidStyle : {}}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="categories-secondaryOwner">
                      <Form.Label column sm="4">
                        Secondary Business Owner
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          control={control}
                          name="secondaryOwner"
                          as={Select}
                          defaultValue={users.find(
                            u =>
                              u.value ===
                              selectedSubCategory?.owners.find(
                                o => o.isPrimary !== "true"
                              )?.userId
                          )}
                          options={users}
                          placeholder={`Select...`}
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Col>
            </Row>
            <Form.Group as={Row} controlId="categories-Dashboards">
              <Form.Label column sm="2">
                Dashboards
                {/* {!selectedTreeItem?.children.length && "*"} */}
              </Form.Label>
              <Col sm="10">
                <MultiSelectSort
                  value={selectedDashboards}
                  options={dashboards}
                  onOptionsChange={handleSelectDashboards}
                />
                {!!errors.contentList && (
                  <span
                    className="text-error mt-1 font-small"
                    style={{ color: "red" }}
                  >
                    {errors.contentList.message}
                  </span>
                )}
              </Col>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this Category Level ${
            !!selectedTreeItem?.children.length
              ? `, as there ${
                  selectedTreeItem?.children.length > 1
                    ? "are sub categories"
                    : "is sub category"
                }  associated with this Category Level.`
              : ""
          }?`}
          title={`Delete Category Level Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={handleCloseDelModal}
          handleYes={() => {
            handleDeleteClick(delId);
            handleCloseDelModal();
          }}
        />
      )}
    </div>
  );
}

export default CategoryManagement;
