import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import {
  fetchTestimonials,
  createTestimonial,
  trackUserAction
} from "../../services/dataService";
import Grid from "../../components/Grid";
import AccentButton from "../../components/AccentButton";
import { useAlertsContext } from "../../context/alerts";

import "./Testimonials.scss";
import Spinner from "../../components/Spinner";
import ModalCard from "../../components/ModalCard";

function Testimonials(props) {
  const [show, setShow] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, errors, reset } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();

  const [delId, setDelId] = useState(null);

  const handleCloseDelModal = () => {
    setDelId(null);
  };
  const handleShowDelModal = id => {
    setDelId(id);
  };

  useEffect(() => {
    fetchTestimonials()
      .then(res => {
        setTestimonials(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Testimonials",
          targetPath: "/admin/testimonials",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  }, []);

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setShow(false);
  };

  const handleNewTestimonialClick = () => {
    setShow(true);
  };

  const onSubmit = async data => {
    const itemID = itemForEdit ? itemForEdit.itemID : null;
    const isEdit = !!itemID;
    const actionType = isEdit ? "edit" : "add";

    try {
      const {
        data: [response]
      } = await createTestimonial({ ...data, actionType, itemID });
      await trackUserAction({
        action: isEdit ? "Edit" : "Create",
        targetType: "Admin",
        targetName: "Testimonials",
        targetPath: "/admin/testimonials",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      handleCloseModal();
      addSuccessAlert(`Testimonial ${isEdit ? "updated" : "created"}`);

      if (isEdit) {
        const index = testimonials.findIndex(i => i.itemID === response.itemID);
        if (index !== undefined) {
          // Insert new updated row into the grid at the correct index
          setTestimonials(items =>
            items.map((i, idx) => {
              if (idx === index) {
                return response;
              }
              return i;
            })
          );
        }
      } else {
        // Add new row to the end
        setTestimonials([...testimonials, response]);
      }
    } catch (err) {
      handleCloseModal();
      addFailAlert("Something went wrong");
    }
  };

  const handleDeleteClick = async itemID => {
    try {
      await createTestimonial({ itemID, actionType: "delete" });
      await trackUserAction({
        action: "Delete",
        targetType: "Admin",
        targetName: "Testimonials",
        targetPath: "/admin/testimonials",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("Testimonial deleted");
      setTestimonials(items => items.filter(i => i.itemID !== itemID));
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleEditClick = testimonial => {
    setItemForEdit(testimonial);
    reset(testimonial);
    setShow(true);
  };

  return (
    <div className="Testimonials">
      <Button className="mb-3" onClick={handleNewTestimonialClick}>
        <AiOutlinePlus /> New Testimonial
      </Button>

      {loading ? (
        <Spinner>Fetching Testimonials</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Testimonials"
          columnDefs={[
            {
              field: "firstName",
              headerName: "First Name",
              sortable: true,
              resizable: true
            },
            {
              field: "lastName",
              headerName: "Last Name",
              sortable: true,
              resizable: true
            },
            {
              field: "designation",
              headerName: "Title",
              sortable: true,
              resizable: true
            },
            {
              field: "testimonial",
              headerName: "Testimonial",
              sortable: true,
              resizable: true
            },
            {
              field: "testimonialDate",
              headerName: "Created At",
              sortable: true,
              resizable: true
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton onClick={() => handleEditClick(data)}>
                      <AiOutlineEdit /> Edit
                    </AccentButton>
                    <AccentButton
                      className="sx-accent-button--danger ml-2"
                      onClick={() => handleShowDelModal(data.itemID)}
                    >
                      <AiOutlineDelete /> Delete
                    </AccentButton>
                  </>
                );
              }
            }
          ]}
          rowData={testimonials}
        />
      )}

      <Modal
        className="testimonials-modal"
        show={show}
        onHide={handleCloseModal}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {!!itemForEdit ? "Edit" : "Add New"} Testimonial
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group as={Row} controlId="testimonials-first-name">
              <Form.Label column sm="2">
                First Name*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="firstName"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.firstName}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="testimonials-last-name">
              <Form.Label column sm="2">
                Last Name*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="lastName"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.lastName}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="testimonials-designation">
              <Form.Label column sm="2">
                Title
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="designation"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.designation}
                  maxLength={250}
                />
              </Col>
            </Form.Group>
            {/* <Form.Group as={Row} controlId="testimonials-department">
              <Form.Label column sm="2">
                Department
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="department"
                  ref={register}
                  type="text"
                  placeholder=""
                />
              </Col>
            </Form.Group> */}
            <Form.Group as={Row} controlId="testimonials-testimonial">
              <Form.Label column sm="2">
                Description*
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="textarea"
                  name="testimonial"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.testimonial}
                  maxLength={4000}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this ${`Testimonial`}?`}
          title={`Delete ${`Testimonial`} Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={handleCloseDelModal}
          handleYes={() => {
            handleDeleteClick(delId);
            handleCloseDelModal();
          }}
        />
      )}
    </div>
  );
}

export default Testimonials;
