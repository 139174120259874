import React, { useState, useEffect } from "react";

import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import BaseCard from "../../components/BaseCard";
import AccentButton from "../../components/AccentButton";

import "./DataOperations.scss";
import { submitOperation, trackUserAction } from "../../services/dataService";
import { useAlertsContext } from "../../context/alerts";

function DataOperations(props) {
  const [operation, setOperation] = useState("load");
  const [values, setValues] = useState({
    jobVOList: [
      {
        cacheCount: "",
        name: "Assets Job",
        status: "",
        tdcCount: ""
      },
      {
        cacheCount: "",
        name: "Assets Desc Job",
        status: "",
        tdcCount: ""
      },
      {
        cacheCount: "",
        name: "Terms Job",
        status: "",
        tdcCount: ""
      },
      {
        cacheCount: "",
        name: "Data Profile Job",
        status: "",
        tdcCount: ""
      }
    ],
    message: "",
    readMode: ""
  });
  const { addFailAlert } = useAlertsContext();

  useEffect(() => {
    if (operation === "") {
      return;
    }

    Promise.all([submitOperation(operation)])
      .then(([response]) => {
        let data = response.data;
        if (operation !== "refresh") {
          trackUserAction({
            action: operation.split("/")[0],
            errorDetails: "",
            status: data.jobVOList.filter(j => j.status === "RUNNING").length
              ? "RUNNING"
              : "COMPLETED",
            targetID:
              operation.split("/")[operation.split("/").length - 1] || "",
            targetName: operation.split("/").join("-"),
            targetPath: operation,
            targetType: "job-execution"
          });
        }
        setValues(data);
        if (
          operation !== "refresh" ||
          data.jobVOList.filter(j => j.status === "RUNNING").length
        ) {
          return setTimeout(() => setOperation("refresh"), 5000);
        }
      })
      .catch(err => {
        addFailAlert("Something went wrong running the operation");
      });

    setOperation("");
  }, [operation, addFailAlert]);

  const assetsObj = values.jobVOList.filter(j => j.name === "Assets Job")[0];
  const assetsDescObj = values.jobVOList.filter(
    j => j.name === "Assets Desc Job"
  )[0];
  const termsObj = values.jobVOList.filter(j => j.name === "Terms Job")[0];
  const dataProfilesObj = values.jobVOList.filter(
    j => j.name === "Data Profile Job"
  )[0];

  return (
    <BasePage className="DataOperations">
      <BaseCard>
        <div className="d-flex align-items-center py-2 px-3">
          <span className="text-primary font-large">
            Insights Marketplace - Data Catalog Operations
          </span>
        </div>

        <section className="section-features">
          <Container fluid className="py-1 huge-container">
            <div className="text-primary font-medium pb-2">
              <p>
                <em>Note</em>: DataCatalog cache will be refreshed automatically
                between (12:00 AM - 3:00 AM).
              </p>
              Follow below steps to refresh cache on-demand.
              <ol type="1">
                <li>{"<Run Assets Job> to complete status"}</li>
                <li>{"<Run Terms Job> to complete status"}</li>
                <li>{"<Run Pending Assets Desc Job> to complete status"}</li>
                <li>{"<Run Data Profile Job> to complete status"}</li>
                <li>
                  Keep this page open while any job is in the running status
                </li>
              </ol>
            </div>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Table>
                  <tbody>
                    <tr className="primary-tr">
                      <td>NAME</td>
                      <td>DESCRIPTION</td>
                      <td>ACTION</td>
                    </tr>
                    <tr className="default-tr">
                      <td>Read Mode</td>
                      <td>{values.readMode}</td>
                      <td>{values.message}</td>
                    </tr>
                    <tr className="secondary-tr">
                      <td>ASSET JOB</td>
                      <td></td>
                      <td>Last Modified: {assetsObj.lastModifiedDate}</td>
                    </tr>
                    <tr className="default-tr">
                      <td>TDC Count</td>
                      <td>{assetsObj.tdcCount}</td>
                      <td></td>
                    </tr>
                    <tr className="default-tr">
                      <td>Assets cache count</td>
                      <td>{assetsObj.cacheCount}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("clear/assets")}
                          className="mr-2 mb-2"
                        >
                          CLEAR ASSETS CACHE
                        </AccentButton>
                      </td>
                    </tr>
                    <tr className="default-tr">
                      <td>Assets Job</td>
                      <td>{assetsObj.status}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("execute/assets")}
                          className="mr-2 mb-2"
                        >
                          RUN ASSETS JOB
                        </AccentButton>
                        <AccentButton
                          onClick={() => setOperation("execute/pending/assets")}
                          className="mr-2 mb-2"
                        >
                          RUN PENDING ASSETS
                        </AccentButton>
                        <AccentButton
                          onClick={() => setOperation("cancel/assets")}
                          className="mr-2 mb-2"
                        >
                          CANCEL ASSETS
                        </AccentButton>
                      </td>
                    </tr>
                    <tr className="secondary-tr">
                      <td>TERM JOB</td>
                      <td></td>
                      <td>Last Modified: {termsObj.lastModifiedDate}</td>
                    </tr>
                    <tr className="default-tr">
                      <td>Term Count</td>
                      <td>{termsObj.tdcCount}</td>
                      <td></td>
                    </tr>
                    <tr className="default-tr">
                      <td>Term Cache Count</td>
                      <td>{termsObj.cacheCount}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("clear/terms")}
                          className="mr-2 mb-2"
                        >
                          CLEAR TERM CACHE
                        </AccentButton>
                      </td>
                    </tr>
                    <tr className="default-tr">
                      <td>Term Job</td>
                      <td>{termsObj.status}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("execute/terms")}
                          className="mr-2 mb-2"
                        >
                          RUN TERM JOB
                        </AccentButton>
                        <AccentButton
                          onClick={() => setOperation("execute/pending/terms")}
                          className="mr-2 mb-2"
                        >
                          RUN PENDING TERMS
                        </AccentButton>
                        <AccentButton
                          onClick={() => setOperation("cancel/terms")}
                          className="mr-2 mb-2"
                        >
                          CANCEL TERMS
                        </AccentButton>
                      </td>
                    </tr>
                    <tr className="secondary-tr">
                      <td>ASSET DESC JOB</td>
                      <td></td>
                      <td>Last Modified: {assetsDescObj.lastModifiedDate}</td>
                    </tr>
                    <tr className="default-tr">
                      <td>TDC Count</td>
                      <td>{assetsDescObj.tdcCount}</td>
                      <td></td>
                    </tr>
                    <tr className="default-tr">
                      <td>Assets desc cache count</td>
                      <td>{assetsDescObj.cacheCount}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("clear/assetsdesc")}
                          className="mr-2 mb-2"
                        >
                          CLEAR ASSET DESC CACHE
                        </AccentButton>
                      </td>
                    </tr>
                    <tr className="default-tr">
                      <td>Assets desc job</td>
                      <td>{assetsDescObj.status}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("execute/assetsdesc")}
                          className="mr-2 mb-2"
                        >
                          RUN ASSETS DESC JOB
                        </AccentButton>
                        <AccentButton
                          onClick={() =>
                            setOperation("execute/pending/assetsdesc")
                          }
                          className="mr-2 mb-2"
                        >
                          RUN PENDING ASSETS DESC
                        </AccentButton>
                        <AccentButton
                          onClick={() => setOperation("cancel/assetsdesc")}
                          className="mr-2 mb-2"
                        >
                          CANCEL ASSETS DESC
                        </AccentButton>
                      </td>
                    </tr>

                    <tr className="secondary-tr">
                      <td>DATA PROFILE JOB</td>
                      <td></td>
                      <td>Last Modified: {dataProfilesObj.lastModifiedDate}</td>
                    </tr>
                    <tr className="default-tr">
                      <td>Data Profile Cache Count</td>
                      <td>{dataProfilesObj.cacheCount}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("clear/dataprofile")}
                          className="mr-2 mb-2"
                        >
                          CLEAR DATA PROFILE CACHE
                        </AccentButton>
                      </td>
                    </tr>
                    <tr className="default-tr">
                      <td>Data Profile Job</td>
                      <td>{dataProfilesObj.status}</td>
                      <td>
                        <AccentButton
                          onClick={() => setOperation("execute/dataprofile")}
                          className="mr-2 mb-2"
                        >
                          RUN DATA PROFILE JOB
                        </AccentButton>
                        <AccentButton
                          onClick={() =>
                            setOperation("execute/pending/dataprofile")
                          }
                          className="mr-2 mb-2"
                        >
                          RUN PENDING DATA PROFILES
                        </AccentButton>
                        <AccentButton
                          onClick={() => setOperation("cancel/dataprofile")}
                          className="mr-2 mb-2"
                        >
                          CANCEL DATA PROFILE
                        </AccentButton>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="pull-right">
                  <AccentButton
                    onClick={() => setOperation("refresh")}
                    className="mr-2 mb-2 float-left"
                  >
                    Refresh
                  </AccentButton>
                  <AccentButton
                    onClick={() => setOperation("executeAllJobs")}
                    className="mr-2 mb-2"
                  >
                    Execute All Jobs
                  </AccentButton>
                  <AccentButton
                    onClick={() => setOperation("save")}
                    className="mr-2 mb-2"
                  >
                    Save
                  </AccentButton>
                  <AccentButton
                    onClick={() => setOperation("load")}
                    className="mr-2 mb-2"
                  >
                    Load
                  </AccentButton>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </BaseCard>
    </BasePage>
  );
}

export default DataOperations;
