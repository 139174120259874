import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineEdit } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import {
  fetchUsers,
  fetchGroups,
  fetchGroupUsers,
  updateGroupUsers,
  trackUserAction
} from "../../services/dataService";
import Grid from "../../components/Grid";
import AccentButton from "../../components/AccentButton";
import { useAlertsContext } from "../../context/alerts";

import flatMap from "lodash/flatMap";
import map from "lodash/map";
import Spinner from "../../components/Spinner";

function GroupManagement(props) {
  const [show, setShow] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { register, handleSubmit, reset } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();

  useEffect(() => {
    fetchUsers().then(res => {
      setUsers(
        res.data
          .filter(user => user.userStatus === "A")
          .map(u => {
            return { ...u, value: u.userId, label: u.fullName };
          })
      );
    });
    fetchGroups().then(res => {
      const data = flatMap(
        res.data,
        ({ CategoryId, CategoryName, subcategories }) =>
          map(subcategories, subcategory => ({
            id: "" + subcategory.SubCategoryId,
            CategoryId,
            CategoryName,
            ...subcategory
          }))
      );
      setCategoryGroups(data);
      setLoading(false);
      trackUserAction({
        action: "List",
        targetType: "Admin",
        targetName: "Groups",
        targetPath: "/admin/groups",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
    });
  }, []);

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setShow(false);
  };

  const handleCloseUsersModal = () => {
    setSelectedGroup(null);
    setShowUsers(false);
  };

  /* const handleNewUserClick = () => {
    setShow(true);
  }; */

  const onSubmit = async data => {
    const itemID = itemForEdit ? itemForEdit.id : null;
    const isEdit = !!itemID;

    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data: [response]
      } = await updateGroupUsers({
        groupId: itemID,
        usersList: selectedUsers ? selectedUsers.map(s => s.value).join() : ""
      });
      await trackUserAction({
        action: "Edit",
        targetType: "Admin",
        targetName: "Groups",
        targetPath: "/admin/groups",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      handleCloseModal();
      addSuccessAlert(`Group ${isEdit ? "updated" : "created"}`);

      /* if (isEdit) {
        const index = categoryGroups.findIndex(
          i => i.itemID === response.itemID
        );
        if (index !== undefined) {
          // Insert new updated row into the grid at the correct index
          setCategoryGroups(items =>
            items.map((i, idx) => {
              if (idx === index) {
                return response;
              }
              return i;
            })
          );
        }
      } else {
        // Add new row to the end
        setCategoryGroups([...categoryGroups, response]);
      } */
    } catch (err) {
      handleCloseModal();
      addFailAlert("Something went wrong");
    }
  };

  /* const handleDeleteClick = async data => {
    try {
      await deleteInternalUser({
        userId: data.id,
        usrStatus: data.userstatus === "A" ? "I" : "A",
        usersList: [
          "I",
          data.id,
          data.name,
          data.email ? data.email : "",
          data.phone ? data.phone : "",
          data.dept ? data.dept : " ",
          "I"
        ].join("`")
      });
      addSuccessAlert("User Status Updated");
      //setCategoryGroups(items => items.filter(i => i.userId !== data.userId));
    //   fetchGroups().then(res => {
    //     setCategoryGroups(
    //       flatMap(res.data, ({ CategoryId, CategoryName, subcategories }) =>
    //         map(subcategories, subcategory => ({
    //           CategoryId,
    //           CategoryName,
    //           ...subcategory
    //         }))
    //       )
    //     );
    //   });
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  }; */

  const handleEditClick = group => {
    fetchGroupUsers({
      groupId: group.id
    })
      .then(res => {
        setItemForEdit(group);
        reset(group);

        setSelectedUsers(
          res.data.map(u => {
            return { value: u.id, label: u.name };
          })
        );
        setShow(true);
      })
      .catch(err => {
        addFailAlert("Something went wrong");
      });
  };

  const handleViewUsersClick = group => {
    setSelectedGroup(group);
    setShowUsers(true);
  };

  return (
    <div className="GroupManagement">
      {/* <Button className="mb-3" onClick={handleNewUserClick}>
        <AiOutlinePlus /> New Group
      </Button> */}
      {loading ? (
        <Spinner>Fetching Groups</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Groups"
          showExcel={true}
          suppressRowTransform={true}
          // getRowHeight={params => {
          //   return (
          //     params.api.getSizesForCurrentTheme().rowHeight *
          //     params.data.usergroups.length
          //   );
          // }}
          columnDefs={[
            {
              field: "SubCategoryId",
              headerName: "Sub Category Id",
              sortable: true,
              resizable: true
            },
            {
              field: "CategoryName",
              headerName: "Category Name",
              sortable: true,
              resizable: true
            },
            {
              field: "SubCategoryName",
              headerName: "Sub Category Name",
              sortable: true,
              resizable: true
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton onClick={() => handleEditClick(data)}>
                      <AiOutlineEdit /> Edit
                    </AccentButton>
                  </>
                );
              }
            },
            {
              field: "view",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton onClick={() => handleViewUsersClick(data)}>
                      <AiOutlineEdit /> View Users
                    </AccentButton>
                  </>
                );
              }
            }
          ]}
          rowData={categoryGroups}
        />
      )}

      <Modal className="admin-modal" show={show} onHide={handleCloseModal}>
        <Form onSubmit={handleSubmit(onSubmit)} className="p-3">
          <Modal.Header closeButton>
            <Modal.Title>
              {!!itemForEdit ? "Edit" : "Add New"} Group
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!!itemForEdit ? (
              <div className="px-3">
                <Form.Group as={Row} controlId="categoryGroups-CategoryName">
                  <Form.Label column sm="4">
                    Category Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="CategoryName"
                      readOnly
                      ref={register({})}
                      type="text"
                      placeholder=""
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="categoryGroups-SubCategoryName">
                  <Form.Label column sm="4">
                    Sub Category Name
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="SubCategoryName"
                      readOnly
                      ref={register({})}
                      type="text"
                      placeholder=""
                    />
                  </Col>
                </Form.Group>
              </div>
            ) : (
              <>
                <span>item for add</span>
              </>
            )}
            <div className="px-3 pb-3">
              <span>Users:</span>
              <Select
                isMulti
                value={selectedUsers}
                closeMenuOnSelect={false}
                name="users"
                options={users}
                onChange={selectedUsers => setSelectedUsers(selectedUsers)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        className="admin-modal"
        show={showUsers}
        onHide={handleCloseUsersModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedGroup?.CategoryName} - {selectedGroup?.SubCategoryName}{" "}
            Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid
            width="100%"
            height="375px"
            animateRows
            showExcel={true}
            className="p-3"
            columnDefs={[
              {
                field: "CategoryName",
                headerName: "Category Name",
                sortable: true,
                resizable: true
              },
              {
                field: "SubCategoryName",
                headerName: "SubCategory Name",
                sortable: true,
                resizable: true
              },
              {
                field: "userNTId",
                headerName: "User ID",
                sortable: true,
                resizable: true
              },
              {
                field: "fullName",
                headerName: "Full Name",
                sortable: true,
                resizable: true
              },
              {
                field: "department",
                headerName: "Department",
                sortable: true,
                resizable: true
              },
              {
                field: "email",
                headerName: "Email address",
                sortable: true,
                resizable: true
              }
            ]}
            rowData={users
              .filter(u =>
                u.usergroups.find(
                  ug => "" + ug.groupId === "" + selectedGroup?.id
                )
              )
              .map(user => {
                return {
                  CategoryName: selectedGroup?.CategoryName,
                  SubCategoryName: selectedGroup?.SubCategoryName,
                  userNTId: user.userNTId,
                  fullName: user.fullName,
                  department: user.department,
                  email: user.email
                };
              })}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GroupManagement;
