import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Select, { components } from "react-select";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlinePlus, AiOutlineEdit } from "react-icons/ai";
import { AiOutlineEllipsis } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import {
  fetchUsers,
  // fetchGroups,
  fetchGroups,
  createUser,
  searchInternalUser,
  addUserGroup,
  deleteInternalUser,
  saveEditUserGroup,
  activateInternalUser,
  trackUserAction
} from "../../services/dataService";
import Grid from "../../components/Grid";
import AccentButton from "../../components/AccentButton";
import { useAlertsContext } from "../../context/alerts";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

// import flatMap from "lodash/flatMap";
// import map from "lodash/map";

import "./UserManagement.scss";
import Spinner from "../../components/Spinner";

const invalidStyle = {
  placeholder: base => ({
    ...base,
    fontSize: "1em",
    color: "#dc3545",
    fontWeight: 400
  }),
  container: base => ({
    ...base,
    backgroundColor: "#dc3545",
    padding: 0.5
  })
};

const BtnCellRenderer = props => {
  return (
    <div>
      <span>{props.value[0]?.groupName}</span>
      <OverlayTrigger
        placement="right"
        trigger="click"
        rootClose
        overlay={
          <Popover>
            <Popover.Content style={{ zIndex: 3 }}>
              {props.value.map(v => {
                return <div key={v.groupId}>{v.groupName}</div>;
              })}
            </Popover.Content>
          </Popover>
        }
      >
        {/* e.stopPropagation() is needed to prevent double click events when clicking on the i icon */}

        <Button
          onClick={e => e.stopPropagation()}
          style={{ border: "none", background: "inherit" }}
        >
          {props.value.length > 1 && (
            <AiOutlineEllipsis
              style={{ fontSize: "25px", color: "rgba(0, 0, 0, 0.87)" }}
            />
          )}
        </Button>
      </OverlayTrigger>
    </div>
  );
};

function UserManagement(props) {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [groups, setGroups] = useState([]);
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [isInternal] = useState(true);
  const [intFirstName, setIntFirstName] = useState("");
  const [intLastName, setIntLastName] = useState("");
  const [intEmail, setIntEmail] = useState("");
  const [searchingInternalUsers, setSearchingInternalUsers] = useState(false);
  const [internalUsersList, setInternalUsersList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  // const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedCategoryGroups, setSelectedCategoryGroups] = useState([]);
  const { register, handleSubmit, errors, reset } = useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [invalidGroups, setInvalidGroups] = useState(false);
  const gridApi = useRef(null);

  useEffect(() => {
    fetchUsers().then(res => {
      setUsers(res.data);
      setLoading(false);
      trackUserAction({
        action: "List",
        targetType: "Admin",
        targetName: "Users",
        targetPath: "/admin/users",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
    });
    /* fetchGroups().then(res => {
      setGroups(
        res.data.map(g => {
          return { value: g.id, label: g.name };
        })
      );
    }); */
    fetchGroups().then(res => {
      const data = res.data.map(d => {
        return {
          label: d.CategoryName,
          options: d.subcategories.map(s => {
            return {
              label: s.SubCategoryName,
              value: s.SubCategoryId
            };
          })
        };
      });
      setCategoryGroups(data);
      /* setCategoryGroups(
        flatMap(res.data, ({ CategoryId, CategoryName, subcategories }) =>
          map(subcategories, subcategory => ({
            CategoryId,
            CategoryName,
            ...subcategory
          }))
        ).map(g => {
          return {
            value: g.SubCategoryId,
            label: g.SubCategoryName,
            subText: g.CategoryName
          };
        })
      ); */
    });
  }, []);

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setInternalUsersList([]);
    setSelectedUserList([]);
    setSelectedCategoryGroups([]);
    setIntFirstName("");
    setIntLastName("");
    setIntEmail("");
    setShow(false);
  };

  const handleNewUserClick = () => {
    setShow(true);
  };

  const onSubmit = async data => {
    if (selectedCategoryGroups.length < 1) {
      setInvalidGroups(true);
      return;
    }

    const itemID = itemForEdit ? itemForEdit.itemID : null;
    const isEdit = !!itemID;
    const actionType = isEdit ? "edit" : "add";

    if (isInternal) {
      try {
        if (isEdit) {
          const oldData = users.filter(u => u.userId === itemID)[0];
          await saveEditUserGroup({
            ...oldData,
            ...data,
            fullName: data.firstName + " " + data.lastName,
            groupsList: selectedCategoryGroups
              ? selectedCategoryGroups.map(s => s.value).join()
              : ""
          });
          await trackUserAction({
            action: "Edit",
            targetType: "Admin",
            targetName: "Internal Users",
            targetPath: "/admin/users",
            targetID: "",
            status: "Success",
            errorDetails: ""
          });
        } else {
          const s = selectedUserList;
          const { data: response } = await addUserGroup({
            userNTId: s.userNTId,
            fullName: s.firstName + " " + s.lastName,
            email: s.email,
            title: s.title,
            department: s.department,
            userType: "I",
            userStatus: "A",
            groupsList: selectedCategoryGroups
              ? selectedCategoryGroups.map(s => s.value).join()
              : ""
          });
          await trackUserAction({
            action: "Create",
            targetType: "Admin",
            targetName: "Internal Users",
            targetPath: "/admin/users",
            targetID: "",
            status: "Success",
            errorDetails: ""
          });
          if (response === "User already exists") {
            throw response;
          }
        }
        handleCloseModal();
        fetchUsers().then(res => {
          setUsers(res.data);
        });
        addSuccessAlert(`User ${isEdit ? "updated" : "created"}`);
        /* if (isEdit) {
          const index = users.findIndex(i => i.itemID === response.itemID);
          if (index !== undefined) {
            // Insert new updated row into the grid at the correct index
            setUsers(items =>
              items.map((i, idx) => {
                if (idx === index) {
                  return response;
                }
                return i;
              })
            );
          }
        } else {
          // Add new row to the end
          setUsers([...users, response]);
        } */
      } catch (err) {
        handleCloseModal();
        if (err === "User already exists") {
          addFailAlert(err);
        } else {
          addFailAlert("Something went wrong");
        }
      }
    } else {
      try {
        const {
          data: [response]
        } = await createUser({ data, actionType, itemID });

        await trackUserAction({
          action: "Create",
          targetType: "Admin",
          targetName: "Users",
          targetPath: "/admin/users",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        handleCloseModal();
        addSuccessAlert(`User ${isEdit ? "updated" : "created"}`);

        if (isEdit) {
          const index = users.findIndex(i => i.itemID === response.itemID);
          if (index !== undefined) {
            // Insert new updated row into the grid at the correct index
            setUsers(items =>
              items.map((i, idx) => {
                if (idx === index) {
                  return response;
                }
                return i;
              })
            );
          }
        } else {
          // Add new row to the end
          setUsers([...users, response]);
        }
      } catch (err) {
        handleCloseModal();
        addFailAlert("Something went wrong");
      }
    }
  };

  const handleDeleteClick = async data => {
    try {
      data.userStatus === "A"
        ? await deleteInternalUser({
            userId: "" + data.userId
          })
        : await activateInternalUser({
            userId: "" + data.userId
          });
      await trackUserAction({
        action: data.userStatus === "A" ? "Deactivate" : "Activate",
        targetType: "Admin",
        targetName: "Users",
        targetPath: "/admin/users",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("User Status Updated");
      //setUsers(items => items.filter(i => i.userId !== data.userId));
      fetchUsers().then(res => {
        setUsers(res.data);
      });
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleEditClick = user => {
    const name = user.fullName.split(" ");
    const formattedUser = {
      ...user,
      firstName: name[0],
      lastName: name[1],
      itemID: user.userId
    };
    setItemForEdit(formattedUser);
    reset(formattedUser);
    if (
      user.usergroups.length === 1 &&
      !user.usergroups[0].groupId &&
      !user.usergroups[0].groupName
    ) {
      setSelectedCategoryGroups([]);
    } else {
      setSelectedCategoryGroups(
        user.usergroups.map(g => {
          return { value: g.groupId, label: g.groupName };
        })
      );
    }
    setShow(true);
  };

  const onClickSearchInternal = async data => {
    setSearchingInternalUsers(true);
    try {
      const { data: response } = await searchInternalUser(data);
      await trackUserAction({
        action: "Search",
        targetType: "Admin",
        targetName: "Internal Users",
        targetPath: "/admin/users",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      setInternalUsersList([...response]);
      setSearchingInternalUsers(false);
    } catch (err) {
      //handleCloseModal();
      setSearchingInternalUsers(false);
      addFailAlert("Something went wrong");
    }
  };

  const onSelectionChanged = () => {
    let selectedRows = gridApi.current.getSelectedRows();
    setSubmitDisabled(selectedRows.length === 0);
    setSelectedUserList(selectedRows.length > 0 ? selectedRows[0] : []);
  };

  /* const formatOptionLabel = ({ value, label, subText }) => (
    <div style={{ display: "flex" }}>
      <div>{label}</div>
      <div style={{ color: "#888", marginLeft: "10px", fontSize: "14px" }}>
        {subText}
      </div>
    </div>
  ); */
  const formatOptionLabel = ({ label }) => (
    <div style={{ marginLeft: "10px" }}>{label}</div>
  );

  const GroupHeading = props => (
    <div className="d-flex justify-content-between align-center py-1">
      <components.GroupHeading
        {...props}
        style={{
          marginTop: "4px",
          fontSize: "18px",
          color: "#000",
          fontWeight: "bold"
        }}
      />
      <AccentButton
        onClick={() => {
          const selectGroupsFromCategory = categoryGroups.filter(
            c => c.label === props.children
          )[0].options;
          const uniqueValues = new Set(
            selectGroupsFromCategory.map(c => c.value)
          );
          setSelectedCategoryGroups([
            ...selectGroupsFromCategory,
            ...selectedCategoryGroups.filter(c => !uniqueValues.has(c.value))
          ]);
        }}
      >
        Select All
      </AccentButton>
    </div>
  );

  return (
    <div className="UserManagement">
      <Button className="mb-3" onClick={handleNewUserClick}>
        <AiOutlinePlus /> New User
      </Button>
      {loading ? (
        <Spinner>Fetching Users</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Users"
          showExcel={true}
          suppressRowTransform={true}
          groupDefaultExpanded={0}
          getRowHeight={params => {
            return params.api.getSizesForCurrentTheme().rowHeight;
          }}
          columnDefs={[
            {
              field: "userNTId",
              headerName: "User Id",
              sortable: true,
              resizable: true
            },
            {
              field: "userStatus",
              headerName: "Status",
              sortable: true,
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton
                      className={`sx-accent-button--${
                        data.userStatus === "A" ? "success" : "danger"
                      } mx-auto`}
                      onClick={() => handleDeleteClick(data)}
                    >
                      {data.userStatus === "A" ? "Active" : "Inactive"}
                    </AccentButton>
                  </>
                );
              }
            },
            {
              field: "fullName",
              headerName: "Name",
              sortable: true,
              resizable: true
            },
            {
              field: "department",
              headerName: "Department",
              sortable: true,
              resizable: true
            },
            {
              field: "phone",
              headerName: "Phone No",
              sortable: true,
              resizable: true
            },
            {
              field: "email",
              headerName: "Email",
              sortable: true,
              resizable: true
            },
            {
              field: "usergroups",
              headerName: "User Groups",
              sortable: true,
              cellRendererFramework: BtnCellRenderer
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton onClick={() => handleEditClick(data)}>
                      <AiOutlineEdit /> Edit
                    </AccentButton>
                  </>
                );
              }
            }
          ]}
          rowData={users}
        />
      )}

      <Modal className="users-modal" show={show} onHide={handleCloseModal}>
        {isInternal ? (
          <Form onSubmit={handleSubmit(onSubmit)} className="p-3">
            <Modal.Header closeButton>
              <Modal.Title>
                {!!itemForEdit ? "Edit" : "Add New"} User
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {!!itemForEdit ? (
                <div className="px-3">
                  <span>User Details:</span>
                  <Form.Group as={Row} controlId="users-first-name">
                    <Form.Label column sm="2">
                      First Name
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        name="firstName"
                        readOnly
                        ref={register({})}
                        type="text"
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="users-last-name">
                    <Form.Label column sm="2">
                      Last Name
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        name="lastName"
                        readOnly
                        ref={register({})}
                        type="text"
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="users-email">
                    <Form.Label column sm="2">
                      Email
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="textarea"
                        name="email"
                        readOnly
                        ref={register({})}
                        type="text"
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="users-phone">
                    <Form.Label column sm="2">
                      Phone
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="textarea"
                        name="phone"
                        readOnly
                        ref={register({})}
                        type="text"
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="users-department">
                    <Form.Label column sm="2">
                      Department
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        as="textarea"
                        name="department"
                        readOnly
                        ref={register({})}
                        type="text"
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                </div>
              ) : (
                <>
                  <div>
                    <span className="px-3">Enter user search criteria:</span>
                    <Form.Group
                      as={Row}
                      className="d-flex align-items-center justify-content-between my-0 mx-1 p-0"
                    >
                      <Col sm="3">
                        <input
                          name="intFirstName"
                          type="text"
                          placeholder="First Name"
                          onChange={e => setIntFirstName(e.target.value)}
                        />
                      </Col>
                      <Col sm="3">
                        <input
                          name="intLastName"
                          type="text"
                          placeholder="Last Name"
                          onChange={e => setIntLastName(e.target.value)}
                        />
                      </Col>
                      <Col sm="3">
                        <input
                          name="intEmail"
                          type="text"
                          placeholder="E-mail Id"
                          onChange={e => setIntEmail(e.target.value)}
                        />
                      </Col>
                      <Col sm="2" className="text-center">
                        <Button
                          disabled={
                            intFirstName.length < 3 &&
                            intLastName.length < 3 &&
                            intEmail.length < 3
                          }
                          onClick={() =>
                            onClickSearchInternal({
                              firstName: intFirstName,
                              lastName: intLastName,
                              email: intEmail
                            })
                          }
                        >
                          Search User
                        </Button>
                      </Col>
                      <Col sm="1" className="text-center">
                        {searchingInternalUsers && <Spinner />}
                      </Col>
                    </Form.Group>
                  </div>
                  <Grid
                    width="100%"
                    height="375px"
                    animateRows
                    onGridReady={params => {
                      // Get access to grid api for use within this component
                      gridApi.current = params.api;
                    }}
                    className="p-3"
                    onSelectionChanged={onSelectionChanged}
                    columnDefs={[
                      {
                        headerName: "Name",
                        checkboxSelection: true,
                        sortable: true,
                        resizable: true,
                        cellRendererFramework: ({ data }) =>
                          `${data.firstName} ${data.lastName} (${data.userNTId})`
                      },
                      {
                        field: "department",
                        headerName: "Department",
                        sortable: true,
                        resizable: true
                      },
                      {
                        field: "title",
                        headerName: "Title",
                        sortable: true,
                        resizable: true
                      },
                      {
                        field: "email",
                        headerName: "Email",
                        sortable: true,
                        resizable: true
                      }
                    ]}
                    rowData={internalUsersList}
                  />
                </>
              )}
              {/* <div className="px-3 pb-3">
                <span>User Groups:</span>
                <Select
                  isMulti
                  value={selectedGroups}
                  closeMenuOnSelect={false}
                  name="groups"
                  options={groups}
                  onChange={selectedGroups => setSelectedGroups(selectedGroups)}
                />
              </div> */}
              <div className="px-3 pb-3">
                <span>Category Groups*:</span>
                <Select
                  isMulti
                  value={selectedCategoryGroups}
                  closeMenuOnSelect={false}
                  name="categoryGroups"
                  options={categoryGroups}
                  components={{ GroupHeading }}
                  onChange={selectedCategoryGroups => {
                    setInvalidGroups(false);
                    setSelectedCategoryGroups(selectedCategoryGroups);
                  }}
                  formatOptionLabel={formatOptionLabel}
                  isInvalid={invalidGroups}
                  placeholder={invalidGroups ? `Required` : `Select Type`}
                  styles={invalidGroups ? invalidStyle : {}}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                disabled={!itemForEdit && submitDisabled}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {!!itemForEdit ? "Edit" : "Add New"} User
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group as={Row} controlId="users-first-name">
                <Form.Label column sm="2">
                  First Name*
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="firstName"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors.firstName}
                    maxLength={250}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="users-last-name">
                <Form.Label column sm="2">
                  Last Name*
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="lastName"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors.lastName}
                    maxLength={250}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {/* <Form.Group as={Row} controlId="users-department">
              <Form.Label column sm="2">
                Department
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  name="department"
                  ref={register}
                  type="text"
                  placeholder=""
                />
              </Col>
            </Form.Group> */}
              <Form.Group as={Row} controlId="users-email">
                <Form.Label column sm="2">
                  Email*
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    name="email"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors.email}
                    maxLength={250}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="users-userNTId">
                <Form.Label column sm="2">
                  User Id*
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    name="userNTId"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors.userNTId}
                    maxLength={250}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="users-phone">
                <Form.Label column sm="2">
                  Phone*
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    name="phone"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors.phone}
                    maxLength={250}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="users-department">
                <Form.Label column sm="2">
                  Department*
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    name="department"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors.department}
                    maxLength={250}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default UserManagement;
