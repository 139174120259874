import React, { useContext, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import PaginationTemplate from "../../components/PaginationTemplate";
import { SmartSearchContext } from "./SmartSearchContext";
import { DGCMetricsPopover } from "../../components/AssetCard";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { APP_VARS } from "../../services/appVars";
import DataGovernanceInformation from "../data-catalog/DataGovernanceInformation";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdInformationCircle } from "react-icons/io";

function DataCatalogsList() {
  const {
    datacatalogTabContext,
    pageContext,
    legendContext,
    dataDocumentationContext
  } = useContext(SmartSearchContext);

  const [pageData, setPageData] = pageContext;
  const [, , datacatalogTabData] = datacatalogTabContext;
  const [dataDocumentation, setDataDocumentation] = dataDocumentationContext;

  return (
    <>
      {datacatalogTabData?.length ? (
        <>
          {datacatalogTabData?.map((el, index) => (
            <DataCatalogComponent
              key={index}
              el={el}
              docsData={dataDocumentation.find(
                dc => dc["Data Domain"] === el["domain"]
              )}
            />
          ))}
        </>
      ) : (
        <div className="text-center mb-2">No results found.</div>
      )}

      {!!datacatalogTabData?.length && <LegendComponent />}
      <PaginationTemplate
        setCurrentPage={val => {
          setPageData(j => ({
            ...j,
            datacatalogTab: { ...j.datacatalogTab, currentPage: val }
          }));
        }}
        numberOfPages={pageData?.datacatalogTab?.numberOfPages}
        currentPage={pageData?.datacatalogTab?.currentPage}
      />
    </>
  );
}

export function LegendComponent() {
  const {
    datacatalogTabContext,
    pageContext,
    legendContext,
    dataDocumentationContext
  } = useContext(SmartSearchContext);

  const [legend] = legendContext;
  const [showLegend, setShowLegend] = useState(false);
  return (
    <>
      <Badges />
      <span
        className="sx-info-card__linkText mt-2"
        style={{
          fontSize: "80%",
          fontWeight: "normal",
          display: "flex",
          alignItems: "center",
          columnGap: "5px"
        }}
        onClick={() => {
          setShowLegend(true);
        }}
      >
        <IoMdInformationCircle
          style={{
            fontSize: "170%"
          }}
        />
        <span> What does this mean?</span>
      </span>
      <Modal
        size="xl"
        centered
        show={showLegend}
        dialogClassName="legend-modal"
        onHide={() => {
          setShowLegend(false);
        }}
      >
        <Modal.Header
          style={{
            background: "#e6eaef",
            padding: "0.5rem 1rem",
            fontSize: "2.2rem",
            alignItems: "center"
          }}
        >
          <Modal.Title>Data Certification Legend</Modal.Title>
          <AiOutlineClose
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => setShowLegend(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-2 legend-col">
            <Col lg={2} xl={2}>
              <b>Metrics</b>
            </Col>
            <Col lg={2} xl={2}>
              <b>Description</b>
            </Col>
            <Col lg={2} xl={2} className="d-flex justify-content-between">
              <div>
                <b style={{ paddingTop: "2px" }}>Gold &nbsp;</b>
                <img
                  src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Gold.svg`}
                  alt="gold"
                  height="30px"
                  onClick={e => e.stopPropagation()}
                />
              </div>
              <div style={{ marginRight: "10px" }}>
                <DataGovernanceInformation stars={4} />
              </div>
            </Col>
            <Col lg={2} xl={2} className="d-flex justify-content-between">
              <div>
                <b style={{ paddingTop: "2px" }}>Silver &nbsp;</b>
                <img
                  src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Silver.svg`}
                  alt="silver"
                  height="30px"
                  onClick={e => e.stopPropagation()}
                />
              </div>
              <div style={{ marginRight: "10px" }}>
                <DataGovernanceInformation stars={3} />
              </div>
            </Col>
            <Col lg={2} xl={2} className="d-flex justify-content-between">
              <div>
                <b style={{ paddingTop: "2px" }}>Bronze &nbsp;</b>
                <img
                  src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Bronze.svg`}
                  alt="bronze"
                  height="30px"
                  onClick={e => e.stopPropagation()}
                />
              </div>
              <div style={{ marginRight: "10px" }}>
                <DataGovernanceInformation stars={2} />
              </div>
            </Col>
            <Col lg={2} xl={2} className="d-flex justify-content-between">
              <div>
                <b style={{ paddingTop: "2px" }}>Ore &nbsp;</b>
                <img
                  src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Ore.svg`}
                  alt="ore"
                  height="30px"
                  onClick={e => e.stopPropagation()}
                />
              </div>
              <div style={{ marginRight: "10px" }}>
                <DataGovernanceInformation stars={1} />
              </div>
            </Col>
          </Row>
          <div
            className="mb-2 pb-2 mx-2 "
            style={{ borderBottom: "2px solid #d6dde5" }}
          ></div>
          {legend?.map(lgnd => (
            <Row
              key={lgnd.name}
              className="mb-2 pb-2 mx-2 legend-col"
              style={{ borderBottom: "2px solid #d6dde5" }}
            >
              <Col lg={2} xl={2}>
                <b>{lgnd.name}</b>
              </Col>
              <Col lg={2} xl={2}>
                <span>{lgnd.definition}</span>
              </Col>
              {lgnd.tiers.map(tier => (
                <Col lg={2} xl={2} key={tier.name}>
                  <span>{tier.definition}</span>
                </Col>
              ))}
            </Row>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}

export function DataCatalogComponent({ el, docsData }) {
  return (
    <>
      {/* {JSON.stringify(docsData?.helplink)} */}

      {/*  eslint-disable-next-line */}
      {/* <LinkContainer
        to={`/assetdescription/${el["objectId"]}`}
        className="search-primary font-weight-bold"
      >
        <a href="javascript:void(0);">{el.businessName}</a>
      </LinkContainer>
      <br />
      {el.domain && (
        <>
          <small className="text-muted">{"Domain : " + el.domain}</small>
          <br />
        </>
      )}
      <small className="text-muted">{"Type : Data Asset"}</small>
      <br />
      <small>{el["description"]}</small>
      <br />
      {el["assetOwner"] && (
        <>
          <small>
            {"EDO Contact : "}
            <span className="search-primary font-weight-bold">
              {el["assetOwner"]}
            </span>
          </small>
          <br />
        </>
      )}
      <br /> */}

      <LinkContainer
        to={`/assetdescription/${el["objectId"]}`}
        className="search-primary font-weight-bold"
      >
        {/*  eslint-disable-next-line */}
        <a href="javascript:void(0);">{el["businessName"]}</a>
      </LinkContainer>
      <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
        |
      </span>

      {el["certification"] && el["dgcMetrics"] && (
        <DGCMetricsPopover
          dataGovernanceScore={el["certification"]}
          dgcValues={Object.values(el["dgcMetrics"])}
        />
      )}
      <small className="text-muted ml-2">{el["certification"]}</small>
      <br />
      <small className="text-muted">{el["description"]}</small>
      <br />

      <div>
        <small style={{ color: "#333333" }}>
          {el["type"] && (
            <>
              <b>Type: </b>
              <span style={{ color: "#333333" }}>
                {" "}
                {el["type"].charAt(0).toUpperCase() + el["type"].slice(1)}
              </span>
              <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
                |
              </span>
            </>
          )}
          {el["domain"] && (
            <>
              <b>Domain: </b>
              <span style={{ color: "#333333" }}>{el["domain"]}</span>
              <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
                |
              </span>
            </>
          )}
          {el["name"] && (
            <>
              <b>Data Asset Actual Name: </b>
              <span style={{ color: "#333333" }}>{el["name"]}</span>
              <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
                |
              </span>
            </>
          )}
          {el["platform"] && (
            <>
              <b>Platform: </b>
              <span style={{ color: "#333333" }}>{el["platform"]}</span>
              <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
                |
              </span>
            </>
          )}
          {el["database"] && (
            <>
              <b>Database: </b>
              <span style={{ color: "#333333" }}>{el["database"]}</span>
              <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
                |
              </span>
              <b>EDO Contact: </b>
              <span style={{ color: "#333333" }}>{el["assetOwner"]}</span>
            </>
          )}
        </small>
        {/* {el["certification"] && el["dgcMetrics"] && (
          <DGCMetricsPopover
            dataGovernanceScore={el["certification"]}
            dgcValues={Object.values(el["dgcMetrics"])}
          />
        )} */}
      </div>
      {!!docsData?.helplink && (
        <div className="d-flex align-items-center pb-3 pt-1">
          <small style={{ color: "#333333" }}>
            <b>Documentation: </b>
            {docsData?.helplink?.map((e, i) => (
              <>
                {!!i ? (
                  <span style={{ color: "rgb(227 217 217)" }}>|</span>
                ) : (
                  <></>
                )}
                <a href={e.url} className="mx-2" target="_blank">
                  {e.label}
                </a>
              </>
            ))}
          </small>
        </div>
      )}
      <br />
    </>
  );
}
export const Badges = () => (
  <div
    className="d-flex mt-4"
    style={{
      columnGap: "12px",
      fontSize: "13px",
      color: "#333333"
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <img
        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Gold.svg`}
        alt="gold"
        height="30px"
      />
      <span>Gold</span>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <img
        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Silver.svg`}
        alt="Silver"
        height="30px"
      />
      <span>Silver</span>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <img
        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Bronze.svg`}
        alt="Bronze"
        height="30px"
      />
      <span>Bronze</span>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <img
        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Ore.svg`}
        alt="Ore"
        height="30px"
      />
      <span>Ore</span>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <img
        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Pending.svg`}
        alt="Pending"
        height="30px"
      />
      <span>Pending</span>
    </div>
  </div>
);

export default DataCatalogsList;
