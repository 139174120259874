import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { trackUserAction } from "../../services/dataService";

import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseCard from "../../components/BaseCard";
import IconCard from "../../components/IconCard";
import RecentsWidget from "../../components/RecentsWidget";
import { useRecentsContext } from "../../context/recents";

import classnames from "classnames";

import "./Home.scss";
import { Tabs, Tab } from "../../components/Tabs";
import { useState } from "react";
import { APP_VARS } from "../../services/appVars";

import { useUserPreferencesContext } from "../../context/user-preferences";
import FavoritesWidget from "../../components/FavoritesWidget";
// import IMAGE from "../../components/MenuPageTemplate/landing-image.jpeg";
import { Button } from "react-bootstrap";

const openSourceImg = "/images/open-source-projects.png";
const dataUniversityImg = "/images/data-university.png";
export const getFeatureData = () => [
  {
    title: "Executive Insights",
    subtitle:
      "Financial Reporting, Operating Metrics, Investor Relations and Company News",
    imageUrl: APP_VARS.REACT_APP_ASSETS_URL + "/images/Icons_SXM/EDO Blogs.png",
    variant: "primary",
    linkUrl: "/executive-dashboards"
  },

  {
    title: "Reporting and Dashboard Catalog",
    subtitle:
      "Search, Access, Subscribe and view all reports and dashboards available throughout the Enterprise",
    imageUrl:
      APP_VARS.REACT_APP_ASSETS_URL +
      "/images/Icons_SXM/Reporting and Dashboard Catalog.png",
    variant: "primary",
    linkUrl: "/reportinganddashboard"
  },
  {
    title: "Data Catalog",
    subtitle:
      "Search, access, verify and find data assets thoroughout the Enterprise",
    imageUrl:
      APP_VARS.REACT_APP_ASSETS_URL + "/images/Icons_SXM/Data Catalog.png",
    variant: "primary",
    linkUrl: "/datalanding"
  },
  {
    title: "Enterprise Analytic Tools",
    subtitle: "Get access and training to a rich set of analytic tools.",
    imageUrl:
      APP_VARS.REACT_APP_ASSETS_URL +
      "/images/Icons_SXM/Self Service Data Visualisation.png",
    variant: "primary",
    linkUrl: "/selfservice"
  },
  {
    title: "Playground",
    subtitle:
      "Explore and play with enterprise data and try out new industry leading analytics tools and features",
    imageUrl:
      APP_VARS.REACT_APP_ASSETS_URL +
      "/images/Land-page-icons/playground-active.png",
    variant: "primary",
    linkUrl: "/playgroundlanding"
  },
  {
    title: "Community",
    subtitle: "Read forums, write blogs and view & register for events",
    imageUrl:
      APP_VARS.REACT_APP_ASSETS_URL + "/images/Land-page-icons/community.png",
    variant: "primary",
    linkUrl: "/communitylanding"
  },
  {
    title: "Data University (Beta)",
    subtitle:
      "Advance your skills through self-paced learning, trainings and certifications",
    imageUrl: APP_VARS.REACT_APP_ASSETS_URL + dataUniversityImg,
    variant: "primary",
    linkUrl: "/data-university"
  },
  {
    title: "API Catalog",
    subtitle:
      "Search, Access APIs and services offered by Enterprise Data Office and Publish your APIs",
    imageUrl:
      APP_VARS.REACT_APP_ASSETS_URL +
      "/images/Land-page-icons/api-catalog-disabled.png",
    variant: "disabled",
    disabled: true,
    badgeText: "Coming Soon"
  },
  {
    title: "Open Source Projects",
    subtitle:
      "See how SiriusXM engineers are contributing to active open source projects",
    imageUrl: APP_VARS.REACT_APP_ASSETS_URL + openSourceImg,
    variant: "disabled",
    disabled: true,
    badgeText: "Coming Soon"
  }
];

function Home({ isExecutiveUser }) {
  const history = useHistory();
  const { recents } = useRecentsContext();
  const { favorites } = useUserPreferencesContext();

  const [activeTab, setActiveTab] = useState("recents");

  useEffect(() => {
    if (favorites.length === 0) {
      setActiveTab("recents");
    } else if (recents.length === 0) {
      setActiveTab("favorites");
    }
  }, [favorites, recents]);

  const setTiles = item => (
    <Col key={item.title} xl={3} lg={4} md={6} sm={12}>
      <IconCard
        shadow
        style={{ height: "calc(100% - 5rem)" }}
        className={classnames("mb-5 p-5", {
          "grow pointer": item.variant !== "disabled"
        })}
        onClick={() => {
          if (item.linkUrl) {
            trackUserAction({
              action: "View",
              targetType: "feature",
              targetName: item.title,
              targetPath: item.linkUrl,
              targetID: item.title,
              status: "Success",
              errorDetails: ""
            });
            history.push(item.linkUrl);
          }
        }}
        image={<img src={item.imageUrl} alt={item.title} />}
        title={item.title}
        subtitle={item.subtitle}
        variant={item.variant}
        badgeText={item.badgeText}
      ></IconCard>
    </Col>
  );
  return (
    <BasePage className="Home">
      <div className="p-3">
        <BaseCard>
          <div className="d-flex align-items-center py-2 px-3 gray-bottom-border-1">
            <span className="text-primary font-large">
              Welcome to Insights Marketplace
            </span>
            <span className="vertical-bar"></span>
            <span className="text-primary font-medium">
              Enterprise Data, Services and Analytics resources all in one place
            </span>
            <Button
              className="learn-more-btn d-flex align-items-center"
              onClick={() => history.push("/about")}
            >
              <span className="mx-2">Learn More</span>
            </Button>
          </div>

          {/* <BaseCard>
            <section className="section-banner">
              <img src={IMAGE} alt="cover" />
              <div className="text-center banner-text">
                <div className="header-text">
                  SiriusXM. Welcome to Insights Marketplace
                </div>
                <div className="subtext">
                  Your one stop shop to collaboratively create, find and share
                  enterprise data assets
                </div>
                <Button
                  className="learn-more-btn d-flex align-items-center"
                  onClick={() => history.push("/about")}
                >
                  <span className="mx-2">Learn More</span>
                </Button>
              </div>
            </section>
          </BaseCard> */}

          {recents.length + favorites.length > 0 && (
            <div>
              <Tabs
                mountOnEnter
                activeKey={activeTab}
                onSelect={k => setActiveTab(k)}
              >
                {recents.length > 0 && (
                  <Tab eventKey="recents" title="Recent">
                    <section className="section-recents">
                      <RecentsWidget recents={recents} />
                    </section>
                  </Tab>
                )}
                {favorites.length > 0 && (
                  <Tab eventKey="favorites" title="Favorites">
                    <section className="section-recents">
                      <FavoritesWidget favorites={favorites} />
                    </section>{" "}
                  </Tab>
                )}
              </Tabs>
            </div>
          )}

          <section className="section-features">
            <Container fluid className="py-5 huge-container">
              <Row>
                {isExecutiveUser.toLowerCase() === "true"
                  ? getFeatureData().map(item => setTiles(item))
                  : getFeatureData()
                      .filter(el => el.title !== "Executive Insights")
                      .map(item => setTiles(item))}
              </Row>
            </Container>
          </section>
        </BaseCard>
      </div>
    </BasePage>
  );
}

export default Home;
