import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useRouteMatch, Switch, Redirect, Route } from "react-router-dom";

import BasePage from "../../components/layouts/BasePage";
import DataOperations from "./DataOperations";
import NavLinkList from "../../components/NavLinkList/NavLinkList";
import Testimonials from "./Testimonials";
import Events from "./Events";
import Alerts from "./Alerts";
import UserManagement from "./UserManagement";
import GroupManagement from "./GroupManagement";
import DashboardManagement from "./DashboardManagement";
import SchedulesManagement from "./SchedulesManagement";
import AppVariables from "./AppVariables";
import { APP_VARS } from "../../services/appVars";
import "./admin-styles.scss";
import DataUniversityManagement from "./DataUniversityManagement";

const eventsImg = "/images/EVENTS.png";
const testimonialsImg = "/images/TESTIMONIALS.png";
const usersImg = "/images/USERS.png";
const dataOperationsImg = "/images/Icons_SXM/Data Catalog.png";

function AdminShell(props) {
  const { path } = useRouteMatch();

  const adminData = [
    {
      title: "Events",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/events"
    },
    {
      title: "Testimonials",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + testimonialsImg,
      linkUrl: "/admin/testimonials"
    },
    {
      title: "User Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + usersImg,
      linkUrl: "/admin/users"
    },
    {
      title: "Group Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + usersImg,
      linkUrl: "/admin/groups"
    },
    {
      title: "Dashboard Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/dashboards"
    },
    {
      title: "Schedules Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + usersImg,
      linkUrl: "/admin/schedules"
    },
    {
      title: "Alerts",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/alerts"
    },
    {
      title: "Data University",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/data-university"
    },
    {
      title: "Data Operations",
      subtitle:
        "Setup data assets for enabling search, access and discover of data assets throughout the Enterprise",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + dataOperationsImg,
      variant: "primary",
      linkUrl: "/admin/dataoperations"
    },
    {
      title: "App Variables",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + dataOperationsImg,
      linkUrl: "/admin/app-variables"
    }
  ];

  return (
    <BasePage>
      <Container fluid>
        <Row>
          <Col md={4} lg={3} xl={2} sm={12} className="p-0">
            <NavLinkList headerText="Admin Panel" links={adminData} />
          </Col>
          <Col md={8} lg={9} xl={10} sm={12} className="p-4">
            <Switch>
              {/* If landing on "/admin" then redirect to "/admin/events" */}
              <Redirect exact path={path} to={`${path}/events`} />
              <Route exact path={`${path}/events`}>
                <Events />
              </Route>
              <Route exact path={`${path}/testimonials`}>
                <Testimonials />
              </Route>
              <Route exact path={`${path}/users`}>
                <UserManagement />
              </Route>
              <Route exact path={`${path}/groups`}>
                <GroupManagement />
              </Route>
              <Route exact path={`${path}/dashboards`}>
                <DashboardManagement />
              </Route>
              <Route exact path={`${path}/schedules`}>
                <SchedulesManagement />
              </Route>
              <Route exact path={`${path}/alerts`}>
                <Alerts />
              </Route>
              <Route exact path={`${path}/data-university`}>
                <DataUniversityManagement />
              </Route>
              <Route exact path={`${path}/dataoperations`}>
                <DataOperations />
              </Route>
              <Route exact path={`${path}/app-variables`}>
                <AppVariables />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    </BasePage>
  );
}

export default AdminShell;
