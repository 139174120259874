import groupBy from "lodash/groupBy";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import BasePage from "../../components/layouts/BasePage";
import BaseCard from "../../components/BaseCard";
import { getUrlWithNoSpace, dateformat } from "../../services/utils";

import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import { useUserPreferencesContext } from "../../context/user-preferences";
import {
  fetchExecCategories,
  fetchExecDasboards,
  fetchAppVariables,
  trackUserAction
} from "../../services/dataService";
import DashboardResultsView from "./DashboardResultsView";
import { DashboardsContext } from "./DashboardsContext";
import { getValuesByKey, getValueOnlyByKey } from "../../services/utils";

function DashboardCatalogShell({
  globalSearchText,
  setGlobalSearchText,
  activeFilters,
  setActiveFilters
}) {
  const history = useHistory();

  const { path } = useRouteMatch();
  const location = useLocation();
  const { addFailAlert } = useAlertsContext();
  const {
    domainsContext,
    categoryContext,
    dashboardsContext,
    filterContext,
    layoutContext,
    levelsContext,
    subLevelContext
  } = useContext(DashboardsContext);
  const [openAccordionKey, setOpenAccordionKey] = useState();
  const [domainsLoading, setDomainsLoading] = useState(false);
  const [viewAs, setViewAs] = layoutContext;
  const [levels, setLevels, activeLevels, setActiveLevels] = levelsContext;

  const [
    allDomains,
    setAllDomains,
    activeDomain,
    setActiveDomain
  ] = domainsContext;

  const [
    allCategories,
    setAllCategories,
    activeCategory,
    setActiveCategory,
    ,
    setFilteredCategories
  ] = categoryContext;
  const [
    allDashboards,
    setAllDashboards,
    ,
    setActiveDashboard,
    ,
    setFilteredDashboards
  ] = dashboardsContext;
  const [
    filterField,
    setFilterField,
    filterText,
    setFilterText
  ] = filterContext;
  const [
    allSubLevels,
    setAllSubLevels,
    activeSubLevel,
    setActiveSubLevel,
    filteredSubLevels,
    setFilteredSubLevels
  ] = subLevelContext;
  const [domainCounts, setDomainCounts] = useState([]);
  const [categoryCounts, setCategoryCounts] = useState([]);
  const [brandCounts, setBrandCounts] = useState([]);

  useEffect(() => {
    setDomainsLoading(true);

    Promise.all([fetchExecCategories(), fetchExecDasboards()])
      .then(response => {
        let domainsArray = response[0].data.filter(e => e.level === "Level1");
        let categoriesArray = response[0].data.filter(
          e => e.level === "Level2"
        );
        const subLevelsArray = response[0].data.filter(
          e => e.level === "Level3"
        );
        let allLevels = [...new Set(response[0].data.map(el => el.level))];
        let levelObj = {};
        let levelObj2 = {};
        allLevels.forEach(el => {
          levelObj = {
            ...levelObj,
            [el]: [...response[0].data.filter(e => e.level === el)]
          };
        });
        allLevels.forEach(el => {
          levelObj2 = {
            ...levelObj2,
            [el]: null
          };
        });
        setLevels(levelObj);
        setActiveLevels(levelObj2);
        setAllDomains(domainsArray.sort((a, b) => a.sortOrder - b.sortOrder));
        setDomainCounts(
          domainsArray.map(i => ({
            id: i.id,
            count: 0,
            label: i.name,
            dbKeyName: i.name + i.id
          }))
        );

        setCategoryCounts(
          categoriesArray.map(i => ({
            id: i.id,
            count: 0,
            label: i.name,
            dbKeyName: i.name + i.id
          }))
        );
        // -------------------------------------------------------------------------------------------------------------------------------------

        // let allLevels = [...new Set(response[0].data.map(el => el.level))];
        // console.log(allLevels);

        // let context1Data = null;
        // allLevels.forEach(el => {
        //   context1Data = {
        //     ...context1Data,
        //     [el]: response[0].data.filter(e => e.level === el)
        //   };
        // });
        // // console.log("context 1", context1Data);

        // let context2Data = null;

        // allLevels.forEach(el => {
        //   context2Data = {
        //     ...context2Data,
        //     [el]: null
        //   };
        // });

        // console.log("context 2", context2Data);

        // let context3Data = {};
        // let dummyData = null;
        // for (let i = 0; i < allLevels.length; i++) {
        //   if (!i) {
        //     if (!context3Data[allLevels[i]]) {
        //       dummyData = response[0].data.find(e => e.level === allLevels[i]);
        //       context3Data[allLevels[i]] = dummyData;
        //     } else {
        //       dummyData = context3Data[allLevels[i]];
        //     }
        //   } else {
        //     if (!context3Data[allLevels[i]]) {
        //       dummyData = response[0].data.find(
        //         e => e.parentid === dummyData.id
        //       );
        //       context3Data[allLevels[i]] = dummyData;
        //     } else {
        //       dummyData = context3Data[allLevels[i]];
        //     }
        //   }
        // }

        // console.log("Third Context", context3Data);

        // -------------------------------------------------------------------------------------------------------------------------------------

        // setBrandCounts(
        //   response[0].Brands.map(i => ({
        //     id: i.TypeID,
        //     count: i.DBCount,
        //     label: i.NAME,
        //     dbKeyName: "dbBrandID"
        //   }))
        // );

        setAllCategories(
          categoriesArray.sort((a, b) => a.sortOrder - b.sortOrder)
        );
        setAllSubLevels(
          subLevelsArray.sort((a, b) => a.sortOrder - b.sortOrder)
        );
        setAllDashboards(response[1].data);

        const actDomain = JSON.parse(sessionStorage.getItem("activeDomain"));
        if (actDomain) {
          setActiveDomain(actDomain);
        } else setActiveDomain(domainsArray[0]);

        const actCategory = JSON.parse(
          sessionStorage.getItem("activeCategory")
        );
        if (actCategory) {
          setActiveCategory(actCategory);
        }

        const actSublevel = JSON.parse(
          sessionStorage.getItem("activeSublevel")
        );
        if (actSublevel) {
          setActiveSubLevel(actSublevel);
        }
        setDomainsLoading(false);
      })
      .catch(err => {
        addFailAlert("Something went wrong fetching dashboards");
        setDomainsLoading(false);
      });
  }, [addFailAlert]);

  useEffect(() => {
    if (!activeDomain) {
      setActiveDomain(allDomains[0]);
    }

    if (
      !activeCategory &&
      !JSON.parse(sessionStorage.getItem("activeCategory"))
    ) {
      setActiveCategory(
        allCategories.find(i => i.parentid === activeDomain.id)
      );
    }

    setFilteredCategories(
      allCategories
        .filter(c => c.parentid === activeDomain.id)
        .sort((a, b) => a.sortOrder - b.sortOrder)
    );

    if (
      !activeSubLevel &&
      !JSON.parse(sessionStorage.getItem("activeSublevel"))
    ) {
      setActiveSubLevel(
        allSubLevels.find(i => i.parentid === activeCategory?.id)
      );
    }
    //*********************For future reference************************/
    // for (let i = 0; i < Object.keys(activeLevels).length; i++) {
    //   if (!activeLevels[`Level${i + 1}`]) {
    //     if (!i) {
    //       setActiveLevels(prev => {
    //         return {
    //           ...prev,
    //           [`Level${i + 1}`]: levels[`Level${i + 1}`][0]
    //         };
    //       });
    //       continue;
    //     }
    //     setActiveLevels(prev => {
    //       return {
    //         ...prev,
    //         [`Level${i + 1}`]: levels[`Level${i + 1}`].filter(el => {
    //           return el.parentid === activeLevels[`Level${i}`]?.id;
    //         })[0]
    //       };
    //     });
    //   }
    // }
    // console.log("state-2:", activeLevels);
    //******************************************************************/
    setFilteredSubLevels(
      allSubLevels
        .filter(c => c.parentid === activeCategory?.id)
        .sort((a, b) => a.sortOrder - b.sortOrder)
    );

    // First, filter the superset down by Domain
    const dashboardsByDomain = allDashboards.filter(e => {
      if (!activeDomain) {
        return true;
      }
      return e.domain_id === activeDomain?.id;
    });

    // Second, filter the above result by Category (aka subdomain)
    const dashboardsByCategory = dashboardsByDomain.filter(e => {
      if (!activeCategory) {
        return true;
      }
      return e.subdomain_id === activeCategory?.id;
    });

    const dashboardsBySubLevel = allDashboards.filter(e => {
      if (!activeSubLevel && e.categoryLevel === "Level3") {
        return true;
      }
      return e.subdomain_id === activeSubLevel?.id;
    });
    // Third, filter the above result by filtertext
    const tmpFilter = activeSubLevel
      ? dashboardsBySubLevel
      : dashboardsByCategory;
    const dashboardsByFilterText = tmpFilter.filter(e => {
      const stringToCheck =
        viewAs === "list" ? `${e.name} ${e.owner}` : `${e.name}`;
      if (!filterText) {
        return true;
      }
      return stringToCheck.toLowerCase().includes(filterText.toLowerCase());
    });

    // Fourth, filter the above result by Static dropdown field
    const dashboardsByFilterField = dashboardsByFilterText.filter(e => {
      if (!e.filters && e.source !== "GoogleDrive") {
        return true;
      }
      if (!filterField.value) {
        return true;
      }
      // --- filtervalue ----
      return filterField.value
        .toLowerCase()
        .split(" ")
        .every(el =>
          e.filters
            .toLowerCase()
            .split(" ")
            .includes(el)
        );
    });

    setFilteredDashboards(dashboardsByFilterField);

    if (
      activeDomain?.layout === "LSidebar" ||
      activeDomain?.layout === "Basic"
    ) {
      setActiveDashboard(dashboardsByFilterField[0]);
    }

    // ---------------- Code below this line is for future reference ------------------------------------------------------

    // Break active filters out by type
    // const dashboardsBySearchText = allDashboards
    //   .map(c => {
    //     return {
    //       ...c,
    //       authorized: allCategories.filter(a => a.TypeID === c.dbCategoryID)[0]
    //         .authorized
    //     };
    //   })
    //   .filter(j => {
    //     if (globalSearchText) {
    //       const stringToCheck = `${j.dbSource}${j.dbDesc}${j.dbName}${j.dbTags}`;
    //       return (
    //         stringToCheck
    //           .toLowerCase()
    //           .indexOf(globalSearchText.toLowerCase()) > -1
    //       );
    //     }
    //     return true;
    //   });
    // i.e. {"Business Domain": [1234, 5678], "Subdomain": [9548, 3728]}
    // const grouped = groupBy(activeFilters, "type");

    // Filter the previous result by only those who have one of the selected tags
    // const appliedTags = grouped["Tags"];
    // const dashboardsByTags = dashboardsBySearchText.filter(db => {
    //   if (!appliedTags) {
    //     return true;
    //   }
    //   let hasTag = false;
    //   appliedTags.forEach(tag => {
    //     if (
    //       !hasTag &&
    //       db.dbTags &&
    //       db.dbTags.toLowerCase().indexOf(tag.id) > -1
    //     ) {
    //       hasTag = true;
    //     }
    //   });
    //   return hasTag;
    // });

    // Fourth, filter the above result by brands
    // const appliedBrands = grouped["Brands"];
    // const dashboardsByBrand = dashboardsByCategory.filter(i => {
    //   if (!appliedBrands) {
    //     return true;
    //   }
    //   return appliedBrands.map(j => j.id).indexOf(i.dbBrandID) > -1;
    // });

    // Use the db's filtered by search text to get counts by domainId
    // const countsByDomain = dashboardsByTags.reduce((acc, curr) => {
    //   if (!acc[curr.domain_id]) {
    //     acc[curr.domain_id] = 0;
    //   }
    //   acc[curr.domain_id]++;
    //   return acc;
    // }, {});

    // Use the db's filtered by domain to get counts by category (aka subdomain)
    // const countsByCategory = dashboardsByDomain.reduce((acc, curr) => {
    //   if (!acc[curr.subdomain_id]) {
    //     acc[curr.subdomain_id] = 0;
    //   }
    //   acc[curr.subdomain_id]++;
    //   return acc;
    // }, {});

    // Use the db's filtered by category to get counts by brand
    // const countsByBrand = dashboardsByCategory.reduce((acc, curr) => {
    //   if (!acc[curr.dbBrandID]) {
    //     acc[curr.dbBrandID] = 0;
    //   }
    //   acc[curr.dbBrandID]++;
    //   return acc;
    // }, {});

    // setDomainCounts(domCounts =>
    //   domCounts.map(i => ({
    //     ...i,
    //     count: countsByDomain[i.id] || 0
    //   }))
    // );

    // setCategoryCounts(catCounts =>
    //   catCounts.map(i => ({
    //     ...i,
    //     count: countsByCategory[i.id] || 0
    //   }))
    // );

    // setBrandCounts(brandCounts =>
    //   brandCounts.map(i => ({
    //     ...i,
    //     count: countsByBrand[i.id] || 0
    //   }))
    // );
  }, [
    allCategories,
    allDomains,
    allDashboards,
    activeDomain,
    activeCategory,
    filterText,
    filterField,
    activeFilters,
    globalSearchText,
    allSubLevels,
    activeSubLevel
  ]);

  const sideFilterData = [
    {
      name: "Business Domain",
      id: 1,
      counts: domainCounts
    },
    { name: "Subdomain", id: 2, counts: categoryCounts },
    { name: "Brands", id: 3, counts: brandCounts }
  ];

  const handleTagClick = useCallback(
    (tag, path) => {
      trackUserAction({
        action: "Search",
        targetType: "tag",
        targetName: tag,
        targetPath: path,
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
    },
    [setActiveFilters, history, location.pathname]
  );

  const {
    favorites,
    addFavorites,
    removeFavorites
  } = useUserPreferencesContext();

  const onFavToggle = useCallback(
    (isFav, dbId) => {
      isFav ? addFavorites({ dbId: dbId }) : removeFavorites({ dbId: dbId });
    },
    [addFavorites, removeFavorites]
  );

  return (
    <BasePage>
      <Container fluid className="p-4">
        {!domainsLoading ? (
          <Row className="pt-4">
            {/* <Col md={4} lg={3} xl={2} sm={12}>
            <ExpandableMenu
              title={
                <div className="d-flex align-items-center">
                  <FiFilter />
                  <span className="ml-2">Filters</span>
                </div>
              }
              onItemClick={(key, item) => {
                if (key === openAccordionKey) {
                  return setOpenAccordionKey(null);
                }
                if (item.body) {
                  return setOpenAccordionKey(key);
                }
              }}
              activeKey={openAccordionKey}
              items={sideFilterData.map(i => ({
                ...i,
                body: (
                  <div>
                    {i.counts.map(({ label, count, id, dbKeyName }) => (
                      <CheckWithCount
                        key={id}
                        className="mb-2"
                        name={`${i.name}|${id}|${label}`}
                        label={label}
                        checked={!!activeFilters.find(k => k.id === id)}
                        count={count}
                        onChange={e => {
                          trackUserAction({
                            action: "Search",
                            targetType: "filter",
                            targetName: `${i.name}|${id}|${label}`,
                            targetPath: "/dashboardcatalog/search",
                            targetID: id,
                            status: "Success",
                            errorDetails: ""
                          });
                          const filterExists = activeFilters.find(
                            j => j.id === Number(id)
                          );
                          if (filterExists) {
                            return setActiveFilters(af =>
                              af.filter(j => j.id !== id)
                            );
                          }
                          return setActiveFilters(af => [
                            ...af,
                            {
                              type: i.name,
                              value: label,
                              id: id,
                              dbKeyName
                            }
                          ]);
                        }}
                      />
                    ))}
                  </div>
                )
              }))}
            ></ExpandableMenu>
          </Col> */}

            <Col md={12} lg={12} xl={12} sm={12}>
              <DashboardResultsView
                onTagClick={handleTagClick}
                favorites={favorites}
                onFavToggle={onFavToggle}
              />
            </Col>
          </Row>
        ) : (
          <BaseCard className="p-5">
            <Spinner>Loading...</Spinner>
          </BaseCard>
        )}
      </Container>
    </BasePage>
  );
}

export default DashboardCatalogShell;
