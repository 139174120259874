import axios from "axios";
import groupBy from "lodash/groupBy";
// import { getBlob } from "./utils";
import { APP_VARS } from "./appVars";
// import { saveAs } from "file-saver";

let HOST = "";
let API_URL = "";
let BASE = "";
let COGNITO_AUTH_URL = "";
let CMS_HOST = "";

export const initApiValues = () => {
  HOST = APP_VARS.REACT_APP_API_HOST || "";
  API_URL = APP_VARS.REACT_APP_API_ENV;
  BASE = `${HOST}`; //Pointing to Private API
  // BASE = `${HOST}/${API_URL}`; //Pointing to Public API
  COGNITO_AUTH_URL = APP_VARS.REACT_APP_COGNITO_AUTH_URL;
  CMS_HOST = APP_VARS.CMS_HOST;
};

export const getApiUrl = () => API_URL;

export const fetchAuthToken = data =>
  axios.post(`${COGNITO_AUTH_URL}`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: ""
    }
  });

export const fetchUserInfo = () => axios.get(`${BASE}/admin/listuserinfo`);

export const trackUserLogin = data =>
  axios.post(`${BASE}/admin/audit/userlogon`, data);

// User Action Request Schema
//   {
//     action: Action,
//     targetType: TargetType,
//     targetName: TargetName,
//     targetPath: TargetPath,
//     targetID: TargetID,
//     status: Status, //success or fail
//     errorDetails: ErrorDetails
// };
export const trackUserAction = data =>
  axios.post(`${BASE}/admin/audit/useraction`, data);

export const fetchDomains = () =>
  axios.get(`${BASE}/datacatalog/listMasterData?type=Data Domain`);

export const fetchDgcDefinitions = () =>
  axios.get(`${BASE}/datacatalog/listDgcDefinition`);

export const fetchAssets = data => axios.post(`${BASE}/datacatalog/list`, data);

export const fetchCategories = domain =>
  axios.get(`${BASE}/datacatalog/listCategory?domainName=${domain}`);

export const fetchAllFilters = () =>
  axios.get(`${BASE}/datacatalog/listMasterData`);

export const pollPackages = () =>
  axios.get(`${BASE}/myreports/poll/listpackages`, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchPackages = data =>
  axios.post(`${BASE}/myreports/listpackages`, data, {
    headers: { "Content-Type": "application/json" }
  });

export const deleteMyContent = data =>
  axios.post(`${BASE}/invocation/deleteObject`, data, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchDomainAsDb = () =>
  axios.get(`${BASE}/dashboard/getdomainasdb`);

export const fetchDashboardDomainsAndCategories = () =>
  axios
    .get(`${BASE}/dashboard/categories/list`)
    .then(response => groupBy(response.data, "Type"));

export const fetchExecCategories = () =>
  axios.get(`${BASE}/dashboard/exec/categories`);

export const fetchExecDasboards = (data = {}) =>
  axios.get(`${BASE}/dashboard/exec/list`, data);

export const isExecUser = () => axios.get(`${BASE}/admin/exec/access`);

export const fetchDashboards = (data = {}) =>
  axios.get(`${BASE}/dashboard/list`, data);

export const fetchAllGraphData = (name, pageNo, pageSize) =>
  axios.get(
    `${BASE}/datacatalog/smartsearch/list?name=${encodeURIComponent(name)}`
  );

export const fetchDashboardGraphData = (name, pageNo, pageSize) =>
  axios.get(`${BASE}/datacatalog/smartsearch/dashboard/list?name=${name}`);

export const fetchMetricGraphData = (name, pageNo, pageSize) =>
  axios.get(`${BASE}/datacatalog/smartsearch/metric/list?name=${name}`);

export const fetchDataGraphData = (name, pageNo, pageSize) =>
  axios.get(`${BASE}/datacatalog/smartsearch/data/list?name=${name}`);
export const fetchDataUniversityGraphData = (name, pageNo, pageSize) =>
  axios.get(`${BASE}/datacatalog/smartsearch/du/list?name=${name}`);

export const fetchLegend = () =>
  axios.get(`${BASE}/datacatalog/listDgcDefinition`);

export const fetchSmartAssetViewerData = dbName =>
  axios.get(
    `${BASE}/datacatalog/smartsearch/assets/list?dashboardBusinessName=${dbName}`
  );

export const fetchAssetDetails = assetid =>
  axios.get(`${BASE}/datacatalog/listAssetDescription?assetId=${assetid}`, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchDashboardURL = dbid =>
  axios.get(`${BASE}/dashboard/getDBUrl?dbId=${dbid}`, {
    headers: { "Content-Type": "application/json" }
  });

export const pollReports = () =>
  axios.get(`${BASE}/myreports/poll/listreports`, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchReports = data =>
  axios.post(`${BASE}/myreports/listreports`, data, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchInbox = dataParams =>
  axios.post(`${BASE}/myreports/inboxinfo`, "completed", {
    headers: { "Content-Type": "application/json" }
  });

export const deleteInboxReports = data =>
  axios.post(`${BASE}/myreports/deleteReports`, data, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchPromptsCascadeList = dataParams =>
  axios.post(`${BASE}/myreports/cascadelov`, dataParams, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchSampleData = data =>
  axios
    .post(`${BASE}/datacatalog/loadDataSample`, data, {
      headers: { "Content-Type": "application/json" }
    })
    .then(response => {
      if (response.data?.error) {
        console.error(`Error fetching sample data`);
        return [];
      }
      if (!response.data?.result) {
        console.error(`Error fetching sample data`);
        return [];
      }
      return response.data.result;
    });

export const downloadReport = (docID, docType) =>
  axios
    .get(`${BASE}/myreports/download?docID=${docID}&docType=${docType}`, {
      headers: { "Content-Type": "text/plain" }
    })
    .then(response => {
      let a = document.createElement("a");
      a.href = response.data;
      a.target = "_blank";
      a.click();
    });
/* .then(response => {
      const fileName = response.headers["content-disposition"].split(
        "filename="
      )[1];
      const blob = getBlob([response.data], response.headers["content-type"]);
      saveAs(blob, `${fileName}`);
    }); */

//preferences

export const fetchFavorites = () =>
  axios.get(`${BASE}/dashboard/preferences/getfavorites`);

export const submitFavorites = data =>
  axios.post(`${BASE}/dashboard/preferences/addfavorites`, data);

export const fetchRecents = () =>
  axios.get(`${BASE}/dashboard/preferences/getrecents`);

export const submitRecents = data =>
  axios.post(`${BASE}/dashboard/preferences/addrecents`, data);

export const fetchEvents = () => axios.get(`${BASE}/admin/events/list`);

export const updateEvent = data =>
  axios.post(`${BASE}/admin/events/modify`, data);

export const deleteEvent = data =>
  axios.post(`${BASE}/admin/events/delete/${data}`);

export const fetchTestimonials = () =>
  axios.get(`${BASE}/admin/community/gettrendsetters`);

export const createTestimonial = data =>
  axios.post(`${BASE}/admin/community/savetrendsetter`, data);

export const createEvent = data => axios.post(`${BASE}/admin/events/add`, data);

export const deletePreference = prefId =>
  axios.get(`${BASE}/dashboard/preferences/deletepreferences?prefid=${prefId}`);

export const submitOperation = action =>
  axios.get(`${BASE}/datacatalog-admin/${action}`);

export const isAdminRole = () =>
  axios
    .get(`${BASE}/admin/users/isadmin`)
    .then(res => {
      return res.data === "True";
    })
    .catch(() => {
      return false;
    });

export const fetchGroups = () => axios.get(`${BASE}/admin/groups/list`);

export const createUser = data => axios.post(`${BASE}/usersearch/post`, data);

export const addUserGroup = data => axios.post(`${BASE}/admin/users/add`, data);
// // axios.post(`${BASE}/usersearch/addUserGroup`, data);
// axios.post(`${BASE}/admin/users/i/add`, data);

export const fetchUsers = () => axios.get(`${BASE}/admin/users/list`);
// // axios.post(`${BASE}/usersearch/getallusers`);
// axios.get(`${BASE}/admin/users/i/list`);

export const searchInternalUser = data =>
  axios.post(`${BASE}/admin/users/info`, data);

export const deleteInternalUser = data =>
  axios.post(`${BASE}/admin/users/delete`, data);
// // axios.post(`${BASE}/usersearch/deleteUser`, data);
// axios.post(`${BASE}/admin/users/i/delete`, data);

export const activateInternalUser = data =>
  axios.post(`${BASE}/admin/users/activate`, data);
//   axios.post(`${BASE}/admin/users/i/activate`, data);

export const saveEditUserGroup = data =>
  axios.post(`${BASE}/admin/users/update`, data);
// //  axios.post(`${BASE}/usersearch/saveEditUserGroup`, data);
//   axios.post(`${BASE}/admin/users/i/udpate`, data);

// export const fetchCategoryGroups = () => axios.get(`${BASE}/admin/groups/list`);

export const fetchGroupUsers = data =>
  axios.post(`${BASE}/admin/groups/list/users`, data);

export const updateGroupUsers = data =>
  axios.post(`${BASE}/admin/groups/save/users`, data);

export const createAlert = data => axios.post(`${BASE}/admin/alerts/add`, data);

export const updateAlert = data =>
  axios.post(`${BASE}/admin/alerts/update`, data);

export const fetchAlerts = (status = "false") =>
  axios.post(`${BASE}/admin/alerts/list`, { active: status });

export const deleteAlert = data =>
  axios.post(`${BASE}/admin/alerts/delete`, data);

export const fetchAppVariables = () => axios.get(`${BASE}/admin/props/list`);

export const updateAppVariables = data =>
  axios.post(`${BASE}/admin/props/update`, data);

export const fetchContents = () => axios.get(`${BASE}/admin/content/list`);

export const createContent = data =>
  axios.post(`${BASE}/admin/content/add`, data);

export const updateContent = data =>
  axios.post(`${BASE}/admin/content/update`, data);

export const fetchThumbnailList = () =>
  axios.get(`${BASE}/admin/content/upload/list`);

export const deleteContent = data =>
  axios.post(`${BASE}/admin/content/delete`, data);

export const getAllImageFolders = data =>
  axios.post(`${BASE}/admin/content/getallimagefolders`, data);

export const uploadContent = (data, imgFolder) =>
  axios.post(`${BASE}/admin/content/upload?imgFolder=${imgFolder}`, data, {
    headers: { "Content-Type": "multipart/form-data" }
  });

export const fetchAllCategories = () =>
  axios.get(`${BASE}/admin/categories/list`);

export const createCategory = data =>
  axios.post(`${BASE}/admin/categories/add`, data);

export const updateCategory = data =>
  axios.post(`${BASE}/admin/categories/update`, data);

export const saveSortOrder = data =>
  axios.post(`${BASE}/admin/categories/update/sort`, data);

export const deleteCategory = data =>
  axios.post(`${BASE}/admin/categories/delete`, data);

export const fetchAllSchedules = () =>
  axios.get(`${BASE}/admin/schedules/list`);

export const fetchScheduleUsers = data =>
  axios.post(`${BASE}/admin/schedules/listusers`, data);

export const downloadSchedules = () =>
  axios.get(`${BASE}/myreports/schedules/download`);

export const pollDownloadSchedules = data =>
  axios.post(`${BASE}/myreports/schedules/poll/download`, data);

export const createSchedules = () =>
  axios.get(`${BASE}/myreports/schedules/addnew`);

export const pollCreateSchedules = data =>
  axios.post(`${BASE}/myreports/schedules/poll/addnew`, data);

export const updateSchedules = data =>
  axios.post(`${BASE}/myreports/schedules/update`, data);

export const fetchPrompts = dataParams =>
  axios.post(`${BASE}/myreports/getprompts`, dataParams, {
    headers: { "Content-Type": "application/json" }
  });

export const pollPrompts = dataParams =>
  axios.post(`${BASE}/myreports/poll/promptspage`, dataParams, {
    headers: { "Content-Type": "application/json" }
  });

export const submitPrompts = dataParams =>
  axios.post(`${BASE}/myreports/submitprompts`, dataParams, {
    headers: { "Content-Type": "application/json" }
  });

export const pollSubmitPrompts = dataParams =>
  axios.post(`${BASE}/myreports/poll/submitstatus`, dataParams, {
    headers: { "Content-Type": "application/json" }
  });

export const refreshPrompts = dataParams =>
  axios.post(`${BASE}/myreports/getprompts`, dataParams, {
    headers: { "Content-Type": "application/json" }
  });

export const fetchBrands = () => axios.post(`${BASE}/admin/brands/list`);

export const createBrand = data => axios.post(`${BASE}/admin/brands/add`, data);

export const updateBrand = data =>
  axios.post(`${BASE}/admin/brands/update`, data);

export const deleteBrand = data =>
  axios.post(`${BASE}/admin/brands/delete`, data);

export const getStudents = () =>
  axios.get(`${BASE}/admin/users/dustudents/list`);

// Data University APIs
export const validateDuUser = () =>
  axios.get(`${BASE}/data-university/validate`);

export const getDUSeats = () => axios.get(`${BASE}/data-university/seats`);

export const registerDuUser = () =>
  axios.post(`${BASE}/data-university/register`);

export const activateDUUser = () =>
  axios.post(`${BASE}/data-university/activate`);

export const getCourseCategories = () =>
  axios.get(`${BASE}/data-university/course-categories`);

export const getNewCourseCategories = () =>
  axios.get(`${BASE}/data-university/categories`);

export const getLearningPaths = (params = "") =>
  axios.get(`${BASE}/data-university/learning-paths${params}`);

export const getCourses = lp => axios.get(`${BASE}/data-university/courses`);

export const getSectionContents = courseId =>
  axios.get(`${BASE}/data-university/section-contents/${courseId}`);

export const getEnrollments = () =>
  axios.get(`${BASE}/data-university/enrollments`);

export const createEnrollments = courseId =>
  axios.post(`${BASE}/data-university/enrollments/${courseId}`);

export const createCorseraEnrollments = body =>
  axios.post(`${BASE}/coursera/silent-enrollment`, body);

export const updateContentProgress = payload => {
  const { contentProgressId } = payload;
  const contentProgress = payload.progress.toString();
  const contentCompleted = payload.isComplete;
  const courseId = parseInt(payload.cId);
  const id = payload.enrollId;
  return axios.put(`${BASE}/data-university/enrollments/${id}`, {
    contentProgressId,
    contentProgress,
    contentCompleted,
    courseId
  });
};

export const updateProgress = payload => {
  const load = {
    enrollmentId: payload.enrollId,
    courseId: parseInt(payload.cId),
    contentId: payload.contentId,
    contentProgressId: payload.contentProgressId
      ? payload.contentProgressId
      : null,
    contentProgress: payload.progress.toString(),
    contentCompleted: payload.isComplete
  };
  return axios.post(`${BASE}/data-university/content-progress`, load);
};

export const revokeCorseraLicense = body =>
  axios.delete(`${BASE}/coursera/revoke`, body);

export const inviteStudentToCorsera = body =>
  axios.post(`${BASE}/coursera/invite`, body);

export const getCourseraMembershipStatus = () =>
  axios.get(`${BASE}/coursera/membership`);
export const createCourseraMembership = () =>
  axios.post(`${BASE}/coursera/membership`);

export const getEnrollmentBadges = () =>
  axios.get(`${BASE}/data-university/badges`);

export const getEnrollmentProgress = () =>
  axios.get(`${BASE}/coursera/progress`);

export const fetchStagingCourses = () => {
  return axios.get(`${BASE}/data-university/staging-courses`);
};

//"_Psa5V0DEeiCpAqWIh3KHA"
export const addStagingCourse = id =>
  axios.post(`${BASE}/data-university/courses`, {
    courseId: id
  });

export const fetchDUUsers = () => axios.get(`${BASE}/data-university/students`);

export const updateStudent = (id, data) =>
  axios.put(`${BASE}/data-university/students/${id}`, data);

export const getBannerConfig = () =>
  axios.get(`${BASE}/data-university/banner-configs`);

export const addBannerConfig = data =>
  axios.post(`${BASE}/data-university/banner-configs`, data);
export const fetchMetrics = () => axios.get(`${BASE}/data-university/metrics`);

export const addMetrics = data =>
  axios.post(`${BASE}/data-university/metrics`, data);
export const updateMetric = (data, id) =>
  axios.put(`${BASE}/data-university/metrics/${id}`, data);

export const fetchUserPermissions = () =>
  axios.get(`${BASE}/admin/security/user/authorization/MetricsManager`);

export const fetchMarketPlaceFAQs = () =>
  axios.get(`${BASE}/data-university/marketplace-faqs`);
export const fetchDashboardList = () => axios.get(`${BASE}/dashboard/list`);
export const fetchSingleMetricData = metricId =>
  axios.get(`${CMS_HOST}/metrics?TDC_Object_Id=${metricId}`);
// {
// 	"idList": ["155"],
// 	"status": "Approved",
// 	"comment": "looks good"
// }

// End of Data University APIs
export const syncCatalogContent = id =>
  axios.post(`${BASE}/admin/content/catalogsync/${id}`);

export const fetchDataSources = dbId =>
  axios.get(`${BASE}/dashboard/connections/${dbId}`);

export const fetchLdaxList = () => axios.get(`${BASE}/datacatalog/ldax/list`);
export const fetchLdaxData = (id, type = "ldax") => {
  const vid = id ? id : "0000006047315851884";
  return axios.get(`${BASE}/datacatalog/ldax/list/${type}/${vid}`);
};
