import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DashboardCard from "../../components/DashboardCard";
import PaginationTemplate from "../../components/PaginationTemplate";
import AccentButton from "../../components/AccentButton";
import { Button } from "react-bootstrap";
import { FaLock } from "react-icons/fa";
import { APP_VARS } from "../../services/appVars";
import { launchReportInNewWindow } from "../../services/cognosService";
import { trackUserAction } from "../../services/dataService";
import { SmartAssetViewerWidgetDisabled } from "./AllResultsList";
import SmartAssetViewerWidget from "../report-viewer/SmartAssetViewerWidget";
import { SmartSearchContext } from "./SmartSearchContext";
import { S_NOW_LINK } from "../../services/appVars";
import ServiceNowInstructions from "../dashboard-catalog/ServiceNowInstructions";
import { AiFillQuestionCircle } from "react-icons/ai";
import { getUrlWithNoSpace } from "../../services/utils";

function DashboardsList({}) {
  const { dashboardTabContext, pageContext } = useContext(SmartSearchContext);
  const [, , dashboardTabData] = dashboardTabContext;
  const [pageData, setPageData] = pageContext;
  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleOpenModal = () => {
    setShow(true);
  };

  return (
    <>
      {dashboardTabData?.length ? (
        <>
          {dashboardTabData
            .map(el => el.authorized)
            .some(el => el === "False") && (
            <>
              <Row
                className="mx-3 mb-3 text-muted"
                style={{ alignItems: "center" }}
              >
                <FaLock
                  color="#707070"
                  style={{ marginTop: "-3px" }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className="sx-category-card__auth"
                />
                You currently do not have the required permissions to specific
                content. Please submit a ServiceNow to request access.{" "}
                <Button
                  variant="success"
                  className="request-button ml-2"
                  onClick={() => {
                    window.open(S_NOW_LINK, "_blank");
                  }}
                >
                  Request Access
                </Button>
                <div onClick={handleOpenModal} className="pointer ml-2">
                  <AiFillQuestionCircle size={32} color="#a7a9ab" />
                </div>
              </Row>
              <hr />
            </>
          )}
          {dashboardTabData?.map((a, index) => (
            <DashboardComponent key={index} a={a} />
          ))}
        </>
      ) : (
        <>
          <div className="text-center mb-2">No results found.</div>
        </>
      )}
      <ServiceNowInstructions show={show} handleCloseModal={handleCloseModal} />
      <PaginationTemplate
        setCurrentPage={val => {
          setPageData(j => ({
            ...j,
            dashboardTab: { ...j.dashboardTab, currentPage: val }
          }));
        }}
        numberOfPages={pageData?.dashboardTab?.numberOfPages}
        currentPage={pageData?.dashboardTab?.currentPage}
      />
    </>
  );
}
export function DashboardComponent({ a }) {
  const name = getUrlWithNoSpace(a.name);
  return (
    <Row className="mb-3">
      <Col sm={12} md={6} lg={6} xl={2}>
        <DashboardCard
          dbId={a.dbId}
          className="sx-hover-shadow grow mb-4"
          onClick={() => {
            trackUserAction({
              action: "Open",
              targetType: a.dbType,
              targetName: a.businessName,
              targetPath: `/reportviewer/${name}/${a.marketplaceId}`,
              targetID: a.marketplaceId,
              status: "Success",
              errorDetails: ""
            });
            a.authorized === "True" &&
              a.marketplaceId &&
              launchReportInNewWindow(name, a.marketplaceId);
          }}
          imgUrl={`${APP_VARS.REACT_APP_ASSETS_URL}/${
            a.thumbnailUrl?.startsWith("/")
              ? a.thumbnailUrl?.replace("/assets/", "")
              : a.thumbnailUrl?.replace("assets/", "")
          }`}
          showFav={false}
          showTitle={false}
          authorized={a.authorized === "True"}
        />
      </Col>
      <Col sm={12} md={6} lg={6} xl={10}>
        {a.authorized === "True" ? (
          <a
            onClick={() => {
              trackUserAction({
                action: "Open",
                targetType: a.dbType,
                targetName: a.businessName,
                targetPath: `/reportviewer/${name}/${a.marketplaceId}`,
                targetID: a.marketplaceId,
                status: "Success",
                errorDetails: ""
              });
            }}
            href={`/reportviewer/${name}/${a.marketplaceId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer" }}
            className="search-primary font-weight-bold"
          >
            {a.businessName}
          </a>
        ) : (
          <p className="text-muted font-weight-bold mb-n4">{a.businessName}</p>
        )}

        <br />
        <small className="text-muted">
          {a?.description?.length > 200
            ? a.description.substring(0, 200) + " ..."
            : a.description}
        </small>
        <br />
        <small style={{ color: "#333333" }}>
          {a.type && (
            <>
              <b style={{ color: "#333333" }}>Type: </b>
              <span className="text-muted">
                {a["type"].charAt(0).toUpperCase() + a["type"].slice(1)}
              </span>
            </>
          )}
          <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
            |
          </span>
          {a.domain && (
            <>
              <b style={{ color: "#333333" }}>Domain: </b>
              <span className="text-muted">{a.domain}</span>
            </>
          )}
          <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
            |
          </span>
          {a.category && (
            <>
              <b style={{ color: "#333333" }}>Subdomain: </b>
              <span className="text-muted ">{a.category}</span>
            </>
          )}
          <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
            |
          </span>
          {a.assetOwner && (
            <>
              <b style={{ color: "#333333" }}> EDO Contact: </b>
              <span className="text-muted ">{a.assetOwner}</span>
            </>
          )}
        </small>
        <br />
        {a.authorized === "True" && a.hasMetrics === "Y" ? (
          <SmartAssetViewerWidget
            title={a.businessName}
            listView={true}
            bName={a.businessName}
            dbid={a.marketplaceId}
          />
        ) : (
          <SmartAssetViewerWidgetDisabled />
        )}
      </Col>
    </Row>
  );
}
export default DashboardsList;
