import React, { useState, useEffect, useCallback } from "react";

import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import BaseCard from "../../components/BaseCard";
import AccentButton from "../../components/AccentButton";
import groupBy from "lodash/groupBy";
import "./AppVariables.scss";

import {
  fetchAppVariables,
  trackUserAction,
  updateAppVariables
} from "../../services/dataService";
import Spinner from "../../components/Spinner";
import { useForm } from "react-hook-form";
import { useAlertsContext } from "../../context/alerts";
import ExpandableMenu from "../../components/ExpandableMenu";

function AppVariables(props) {
  const [section, setSection] = useState(null);
  const [values, setValues] = useState({});
  const [resData, setResData] = useState({});
  const [updatedProps, setUpdatedProps] = useState([]);
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, errors, reset } = useForm();

  const loadAppVaraibles = useCallback(() => {
    fetchAppVariables()
      .then(res => {
        const aggregatedData = res.data.map(d => {
          return { ...d, shownLabel: `${d.propGroup} - ${d.subGroup}` };
        });
        setResData(aggregatedData);
        const grouped = groupBy(aggregatedData, "shownLabel");
        const subGrouped = Object.keys(grouped).reduce((values, g) => {
          return {
            ...values,
            [g]: grouped[g].reduce((acc, curr) => {
              let key = curr.label || curr.propKey;
              return { ...acc, [key]: curr.value };
            }, {})
          };
        }, {});
        setValues(subGrouped);
        setSection(Object.keys(subGrouped)[0]);
        setLoading(false);
      })
      .catch(() => {
        // addFailAlert("Something went wrong running the operation");
        setLoading(false);
      });
  }, []);

  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  useEffect(() => {
    loadAppVaraibles();
    trackUserAction({
      action: "List",
      targetType: "Admin",
      targetName: "App Variables",
      targetPath: "/admin/app-variables",
      targetID: "",
      status: "Success",
      errorDetails: ""
    });
  }, [loadAppVaraibles]);

  useEffect(() => {
    reset(values[section]);
    setUpdatedProps([]);
  }, [reset, section, values]);

  const onSubmit = () => {
    updateAppVariables({
      properties: updatedProps
    })
      .then(() => {
        addSuccessAlert("Updated App Variables");
        loadAppVaraibles();
        trackUserAction({
          action: "Edit",
          targetType: "Admin",
          targetName: "App Variables",
          targetPath: "/admin/app-variables",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => addFailAlert("Something went wrong running the operation"));
  };

  const handlePropValueChange = e => {
    const oldProperty = groupBy(resData, "shownLabel")[section].filter(
      p => e.target.name === (p.label || p.propKey)
    )[0];
    const updatedPoperty = {
      id: oldProperty.id,
      propKey: oldProperty.propKey,
      propValue: e.target.value,
      label: oldProperty.label,
      encrypt: oldProperty.encrypt,
      sortOrder: oldProperty.sortOrder
    };
    setUpdatedProps([...updatedProps, updatedPoperty]);
  };

  return (
    <BasePage className="AppVariables">
      <BaseCard>
        <div className="d-flex align-items-center py-2 px-3">
          <span className="text-primary font-large">
            Insights Marketplace - App Variables
          </span>
        </div>

        <section className="section-features">
          <Container fluid className="py-4 px-2 huge-container">
            {loading ? (
              <Spinner>Fetching App Variables</Spinner>
            ) : (
              <>
                <Row>
                  <Col>
                    <ExpandableMenu
                      onItemClick={(key, item) => {
                        if (key === section) {
                          return setSection(null);
                        }
                        if (item.body) {
                          return setSection(key);
                        }
                      }}
                      activeKey={section}
                      defaultActiveKey={Object.keys(values)[0]}
                      items={Object.keys(values).map(s => {
                        return {
                          name: s,
                          id: s,
                          body:
                            Object.keys(values[s]).length > 0 ? (
                              <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                  <Col xl={12} lg={12} md={12} sm={12}>
                                    <Table>
                                      <tbody>
                                        <tr className="primary-tr">
                                          <td>NAME</td>
                                          <td>VALUE</td>
                                        </tr>
                                        {Object.keys(values[s]).map(v => (
                                          <tr className="default-tr" key={v}>
                                            <td>{v}</td>
                                            <td>
                                              <Form.Group
                                                controlId={
                                                  v + "-" + values[s][v]
                                                }
                                                className="m-0"
                                              >
                                                <Form.Control
                                                  name={v}
                                                  ref={register({
                                                    required: true,
                                                    validate: val =>
                                                      val.trim() !== ""
                                                  })}
                                                  type={
                                                    groupBy(
                                                      resData,
                                                      "shownLabel"
                                                    )[s].filter(
                                                      p =>
                                                        v ===
                                                        (p.label || p.propKey)
                                                    )[0].encrypt === "TRUE"
                                                      ? "password"
                                                      : "text"
                                                  }
                                                  placeholder=""
                                                  isInvalid={!!errors.v}
                                                  maxLength={250}
                                                  onChange={
                                                    handlePropValueChange
                                                  }
                                                />
                                              </Form.Group>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </Col>
                                  <Col xl={12} lg={12} md={12} sm={12}>
                                    <div className="pull-right">
                                      <AccentButton
                                        onClick={() => {
                                          reset(values[s]);
                                          setUpdatedProps([]);
                                        }}
                                        className="sx-accent-button--connected-vehicle mr-2 mb-2"
                                      >
                                        Reset{" "}
                                      </AccentButton>
                                      <AccentButton
                                        type="submit"
                                        className="mr-2 mb-2"
                                        onClick={() => onSubmit()}
                                      >
                                        Save
                                      </AccentButton>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            ) : (
                              <Container fluid className="p-5">
                                <Row>
                                  <Col sm={12} className="text-center">
                                    No App Variables
                                  </Col>
                                </Row>
                              </Container>
                            )
                        };
                      })}
                    ></ExpandableMenu>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </section>
      </BaseCard>
    </BasePage>
  );
}

export default AppVariables;
