import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import AccentButton from "../../components/AccentButton";
import BaseCard from "../../components/BaseCard";
import Grid from "../../components/Grid";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import { updateMetric } from "../../services/dataService";
import "./AssetManagement.scss";

const configureMetrics = dashboards => {
  if (!!dashboards && dashboards.toString().includes("[")) {
    let newMetrics = JSON.parse(dashboards);
    return newMetrics.map(el => el.name).join(",");
  } else {
    return dashboards;
  }
};

function Metrics({ metricsToShow, metricsLoading }) {
  const { addFailAlert } = useAlertsContext();
  const [metricToEdit, setMetricToEdit] = useState(null);
  const [confirmReview, setConfirmReview] = useState(false);
  // const [isEditor, setIsEditor] = useState(false);
  // let history = useHistory();

  const refreshData = async () => {
    setMetricToEdit(null);
    setConfirmReview(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      // const { data: t } = await fetchUserPermissions();
      // setIsEditor(
      //   t
      //     .map(t => t.permissions.map(t => t.allowedAction))
      //     .flat(1)
      //     .includes("Read")
      // );
    } catch (t) {
      addFailAlert("Something went wrong.");
    }
  };

  const updateReviewStatusFn = async () => {
    await updateMetric(
      {
        ...metricToEdit,
        Status: "Under Review",
        State: "Under Review"
      },
      metricToEdit.id
    );
    refreshData();
  };

  return (
    <Row>
      <Col md={12} lg={12} xl={12} sm={12}>
        <BaseCard>
          <div className="pt-1 pl-2 gray-bottom-border-1">
            <Breadcrumb>
              <LinkContainer to="/">
                <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
              </LinkContainer>
              <LinkContainer to="/datalanding">
                <Breadcrumb.Item as="div">Data Catalog</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>Enterprise Metrics</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* {metricsLoading && <Spinner>Fetching Metrics</Spinner>} */}

          {!metricsLoading && metricsToShow.length === 0 && (
            <div className="text-center">No metrics available.</div>
          )}

          {/* {!metricsLoading && !isEditor && (
            <div className="text-center my-5">Not Authrorized.</div>
          )} */}

          {!metricsLoading && metricsToShow.length !== 0 && (
            <Grid
              width="100%"
              title="Metrics"
              showExcel={true}
              // downloadTitle="Export CSV"
              columnDefs={[
                {
                  field: "Name",
                  headerName: "Metric Name",
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ data, value }) => {
                    return (
                      <>
                        <Link to={"/metricviewer/" + data.TDC_Object_Id}>
                          {value}
                        </Link>
                      </>
                    );
                  }
                },

                {
                  field: "Description",
                  headerName: "Metric Definition",
                  sortable: true,
                  resizable: true
                },
                {
                  field: "Domain",
                  headerName: "Domain",
                  sortable: true,
                  resizable: true
                },
                {
                  field: "Logic",
                  headerName: "Logic",
                  sortable: true,
                  resizable: true
                },
                {
                  field: "Tags",
                  headerName: "Tags",
                  sortable: true,
                  resizable: true
                },
                {
                  field: "Dashboards",
                  headerName: "Primary Dashboard",
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ data, value }) => {
                    return <span>{configureMetrics(data.Dashboards)}</span>;
                    // const dashboardList = value.split(",");
                    // return dashboardList.map(dashboardName => {
                    //   const dbdName = dashboardName
                    //     .replace(/ /gi, "")
                    //     .toLowerCase();
                    //   const dashboardInfo = dashboardMap[dbdName];
                    //   let dashboardUrlInfo = {};
                    //   if (dashboardInfo) {
                    //     dashboardUrlInfo = getDashboardUrlInfo(dashboardInfo);
                    //     return (
                    //       <>
                    //         <a
                    //           href={dashboardUrlInfo.reportviewerUrl || ""}
                    //           target="blank"
                    //         >
                    //           {dashboardUrlInfo.dashboardName}
                    //         </a>{" "}
                    //         &nbsp;&nbsp;
                    //       </>
                    //     );
                    //   } else {
                    //     return (
                    //       <>
                    //         <span>{dashboardName}</span> &nbsp;&nbsp;
                    //       </>
                    //     );
                    //   }
                    // });
                  }
                },
                {
                  field: "DataSource",
                  headerName: "Data Sources",
                  sortable: true,
                  resizable: true
                },
                {
                  field: "StoredProcedure",
                  headerName: "Query",
                  sortable: true,
                  resizable: true
                }
                // {
                //   field: "ModifiedBy",
                //   headerName: "Modified By",
                //   sortable: true,
                //   resizable: true
                // },
                // {
                //   field: "edit",
                //   headerName: "",
                //   resizable: true,
                //   width: 350,
                //   hide: !isEditor,
                //   cellRendererFramework: ({ data, value }) => {
                //     return (
                //       <>
                //         {data.Status !== "Draft" &&
                //         data.Status !== "Approved" &&
                //         data.Status !== "Rejected" &&
                //         data.Status !== "Under Review" ? (
                //           <AccentButton
                //             className="px-3"
                //             onClick={() => {
                //               setMetricToEdit(data);
                //               setConfirmReview(true);
                //             }}
                //           >
                //             Start Review
                //           </AccentButton>
                //         ) : (
                //           <AccentButton
                //             className="px-3"
                //             onClick={() => {
                //               setIsEdit(true);
                //               setMetricToEdit(data);
                //             }}
                //           >
                //             Edit
                //           </AccentButton>
                //         )}
                //         {data.Status === "Under Review" && (
                //           <AccentButton
                //             className="ml-n2 px-3"
                //             onClick={() => {
                //               setMetricToEdit(data);
                //               setIsEdit(true);
                //             }}
                //           >
                //             Continue Review
                //           </AccentButton>
                //         )}
                //       </>
                //     );
                //   }
                // }
              ]}
              rowData={metricsToShow}
            />
          )}
        </BaseCard>
        <Modal
          className="reject-student-modal"
          show={confirmReview}
          onHide={() => {
            setConfirmReview(false);
            setMetricToEdit(null);
          }}
          size="lg"
        >
          {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want to start review process?
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <AccentButton
              className="add-btn edit-btn"
              onClick={() => updateReviewStatusFn()}
            >
              OK
            </AccentButton>
            <AccentButton
              className="ml-2"
              variant="secondary"
              onClick={() => {
                setConfirmReview(false);
                setMetricToEdit(null);
              }}
            >
              Cancel
            </AccentButton>
          </Modal.Body>
          {/* </Form> */}
        </Modal>
      </Col>
    </Row>
  );
}

export default Metrics;
