import React, { useState } from "react";
import { Tab, Tabs } from "../../components/Tabs";
import BasePage from "../../components/layouts/BasePage";
import ContentManagement from "./ContentManagement";
import CategoryManagement from "./CategoryManagement";

import BrandManagement from "./BrandManagement";
import "./DashboardManagement.scss";

function DashboardManagement(props) {
  const [activeTab, setActiveTab] = useState("categories");

  return (
    <BasePage className="DashboardManagement">
      <Tabs
        mountOnEnter
        activeKey={activeTab}
        onSelect={k => setActiveTab(k)}
        className="p-0"
      >
        <Tab eventKey="categories" title="Category Management">
          <section className="p-1 mt-3">
            <CategoryManagement />
          </section>
        </Tab>
        <Tab eventKey="content" title="Content Management">
          <section className="p-1 mt-3">
            <ContentManagement />
          </section>
        </Tab>
        <Tab eventKey="brands" title="Brand Management">
          <section className="p-1 mt-3">
            <BrandManagement />
          </section>
        </Tab>
      </Tabs>
    </BasePage>
  );
}

export default DashboardManagement;
