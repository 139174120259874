import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaFolder, FaLock } from "react-icons/fa";
import PaginationTemplate from "../../components/PaginationTemplate";
import { S_NOW_LINK } from "../../services/appVars";
import ServiceNowInstructions from "../dashboard-catalog/ServiceNowInstructions";
import { DashboardComponent } from "./DashboardsList";
import { DataCatalogComponent, LegendComponent } from "./DataCatalogsList";
import { MetricComponent } from "./MetricsList";
import { SmartSearchContext } from "./SmartSearchContext";

function AllResultsList({ dashboards }) {
  const { allTabContext, pageContext, dataDocumentationContext } = useContext(
    SmartSearchContext
  );
  const [dataDocumentation, setDataDocumentation] = dataDocumentationContext;

  const [, , allTabData] = allTabContext;
  const [pageData, setPageData] = pageContext;
  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleOpenModal = () => {
    setShow(true);
  };
  return (
    <>
      {allTabData.length ? (
        <>
          {allTabData.map(el => el.authorized).some(el => el === "False") && (
            <>
              <Row
                className="mx-3 mb-3 text-muted"
                style={{ alignItems: "center" }}
              >
                <FaLock
                  color="#707070"
                  style={{ marginTop: "-3px" }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className="sx-category-card__auth"
                />
                You currently do not have the required permissions to specific
                content. Please submit a ServiceNow to request access.{" "}
                <Button
                  variant="success"
                  className="request-button ml-2"
                  onClick={() => {
                    window.open(S_NOW_LINK, "_blank");
                  }}
                >
                  Request Access
                </Button>
                <div onClick={handleOpenModal} className="pointer ml-2">
                  <AiFillQuestionCircle size={32} color="#a7a9ab" />
                </div>
              </Row>
              <hr />
            </>
          )}

          {allTabData?.map(a => (
            <>
              {a.type === "dashboard" && <DashboardComponent a={a} />}

              {a.type === "metric" && (
                <MetricComponent
                  el={a}
                  docsData={dataDocumentation.find(
                    dc => dc["Data Domain"] === a["domain"]
                  )}
                />
              )}

              {a.type === "dataset" && (
                <DataCatalogComponent
                  el={a}
                  docsData={dataDocumentation.find(
                    dc => dc["Data Domain"] === a["domain"]
                  )}
                />
              )}
            </>
          ))}
        </>
      ) : (
        <div className="text-center mb-2">No results found.</div>
      )}
      {!!allTabData?.filter(el => el.type === "dataset")?.length && (
        <LegendComponent />
      )}
      <ServiceNowInstructions show={show} handleCloseModal={handleCloseModal} />
      <PaginationTemplate
        setCurrentPage={val => {
          setPageData(j => ({
            ...j,
            allTab: { ...j.allTab, currentPage: val }
          }));
        }}
        numberOfPages={pageData?.allTab?.numberOfPages}
        currentPage={pageData?.allTab?.currentPage}
      />
    </>
  );
}

export function SmartAssetViewerWidgetDisabled() {
  return (
    <>
      <p className="text-info" style={{ opacity: 0.5, cursor: "not-allowed" }}>
        <FaFolder className={"font-small "} />{" "}
        <small className="mt-1">Asset Details</small>
      </p>
      <br />
    </>
  );
}
export default AllResultsList;
