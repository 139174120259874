import groupBy from "lodash/groupBy";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FiFilter } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import CheckWithCount from "../../components/CheckWithCount";
import ExpandableMenu from "../../components/ExpandableMenu";
import BasePage from "../../components/layouts/BasePage";
import { useAlertsContext } from "../../context/alerts";
import {
  fetchDashboardDomainsAndCategories,
  fetchDashboards,
  fetchMetrics,
  fetchUserPermissions,
  trackUserAction
} from "../../services/dataService";
import Metrics from "./Metrics";
import Spinner from "../../components/Spinner";
import BaseCard from "../../components/BaseCard";

function MetricsWrapper({}) {
  const history = useHistory();
  // const { path } = useRouteMatch();
  const { addFailAlert } = useAlertsContext();
  const [openAccordionKey, setOpenAccordionKey] = useState(1);
  const [domainsLoading, setDomainsLoading] = useState(false);
  const [allDomains, setAllDomains] = useState([]);
  // const [allCategories, setAllCategories] = useState([]);
  const [allMetrics, setAllMetrics] = useState([]);
  const [visibleMetrics, setVisibleMetrics] = useState([]);
  const [domainCounts, setDomainCounts] = useState([]);
  // const [categoryCounts, setCategoryCounts] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  // const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    setDomainsLoading(true);
    Promise.all([
      fetchDashboardDomainsAndCategories(),
      fetchDashboards(),
      fetchMetrics(),
      fetchUserPermissions()
    ])
      .then(response => {
        let domainsArray = response[0].Domains;
        setAllDomains(domainsArray.sort((a, b) => a.SortOrder - b.SortOrder));
        let DomainsToAdd = [
          ...new Set(
            response[2].data.filter(el => el.Domain).map(el => el.Domain)
          )
        ].map((i, index) => ({
          id: parseInt(Date.now().toString() + index),
          count: 0,
          label: i,
          dbKeyName: "dbDomainID"
        }));
        // let CategoriesToAdd = [
        //   ...new Set(
        //     response[2].data.filter(el => el.SubDomain).map(el => el.SubDomain)
        //   )
        // ].map((i, index) => ({
        //   id: parseInt(Date.now().toString() + index + index),
        //   count: 0,
        //   label: i,
        //   dbKeyName: "dbCategoryID"
        // }));

        setDomainCounts(DomainsToAdd);

        // setCategoryCounts(CategoriesToAdd);

        // setAllCategories(
        //   response[0].Categories.map(c => {
        //     return { ...c, authorized: c.Authorized === "True" };
        //   })
        // );
        // console.log("Metrics", response[2].data);
        setAllMetrics(response[2].data);
        // setIsEditor(
        //   response[3].data
        //     .map(t => t.permissions.map(t => t.allowedAction))
        //     .flat(1)
        //     .includes("Read")
        // );
        setDomainsLoading(false);
      })
      .catch(err => {
        addFailAlert("Something went wrong fetching dashboards");
        setDomainsLoading(false);
      });
  }, [addFailAlert]);

  useEffect(() => {
    // First, filter the superset down by text search query
    // Break active filters out by type
    // i.e. {"Business Domain": [1234, 5678], "Subdomain": [9548, 3728]}
    const grouped = groupBy(activeFilters, "type");
    // Second, filter the above result by domainId
    const appliedBusinessDomains = grouped["Business Domain"];
    const dashboardsByDomain = allMetrics.filter(i => {
      if (!appliedBusinessDomains) {
        return true;
      }
      return (
        appliedBusinessDomains
          .map(j => j.value.trim())
          .indexOf(i.Domain.trim()) > -1
      );
    });

    // Third, filter the above result by category (aka subdomain)
    const appliedSubdomains = grouped["Subdomain"];
    const dashboardsByCategory = dashboardsByDomain.filter(i => {
      if (!appliedSubdomains) {
        return true;
      }
      return appliedSubdomains.map(j => j.value).indexOf(i.SubDomain) > -1;
    });

    // Use the db's filtered by search text to get counts by domainId
    const countsByDomain = allMetrics.reduce((acc, curr) => {
      if (!acc[curr.Domain]) {
        acc[curr.Domain] = 0;
      }
      acc[curr.Domain]++;
      return acc;
    }, {});

    // Use the db's filtered by domain to get counts by category (aka subdomain)
    // const countsByCategory = dashboardsByDomain.reduce((acc, curr) => {
    //   if (!acc[curr.SubDomain]) {
    //     acc[curr.SubDomain] = 0;
    //   }
    //   acc[curr.SubDomain]++;
    //   return acc;
    // }, {});
    console.log(dashboardsByCategory);
    setVisibleMetrics(dashboardsByCategory);

    setDomainCounts(domCounts =>
      domCounts.map(i => ({
        ...i,
        count: countsByDomain[i.label] || 0
      }))
    );

    // setCategoryCounts(catCounts =>
    //   catCounts.map(i => ({
    //     ...i,
    //     count: countsByCategory[i.label] || 0
    //   }))
    // );
  }, [allDomains, allMetrics, activeFilters]);

  const sideFilterData = [
    {
      name: "Business Domain",
      id: 1,
      counts: domainCounts
    }
    // { name: "Subdomain", id: 2, counts: categoryCounts }
  ];

  return (
    <BasePage>
      <Container fluid className="p-4">
        {domainsLoading && (
          <BaseCard>
            <Spinner>Fetching Enterprise Metrics</Spinner>
          </BaseCard>
        )}
        {!domainsLoading && (
          <Row>
            <Col md={4} lg={3} xl={2} sm={12}>
              <ExpandableMenu
                title={
                  <div className="d-flex align-items-center">
                    <FiFilter />
                    <span className="ml-2">Filters</span>
                  </div>
                }
                onItemClick={(key, item) => {
                  if (key === openAccordionKey) {
                    return setOpenAccordionKey(null);
                  }
                  if (item.body) {
                    return setOpenAccordionKey(key);
                  }
                }}
                activeKey={openAccordionKey}
                items={sideFilterData.map(i => ({
                  ...i,
                  body: (
                    <div>
                      {i.counts.map(({ label, count, id, dbKeyName }) => (
                        <CheckWithCount
                          key={id + label}
                          className="mb-2"
                          name={`${i.name}|${id}|${label}`}
                          label={label}
                          checked={!!activeFilters.find(k => k.id === id)}
                          count={count}
                          onChange={e => {
                            trackUserAction({
                              action: "Search",
                              targetType: "filter",
                              targetName: `${i.name}|${id}|${label}`,
                              targetPath: "/datacatalog/metrics",
                              targetID: id,
                              status: "Success",
                              errorDetails: ""
                            });
                            history.push("/datacatalog/metrics");
                            const filterExists = activeFilters.find(
                              j => j.id === Number(id)
                            );
                            if (filterExists) {
                              return setActiveFilters(af =>
                                af.filter(j => j.id !== id)
                              );
                            }
                            return setActiveFilters(af => [
                              ...af,
                              {
                                type: i.name,
                                value: label,
                                id: id,
                                dbKeyName
                              }
                            ]);
                          }}
                        />
                      ))}
                    </div>
                  )
                }))}
              ></ExpandableMenu>
            </Col>
            <Col md={8} lg={9} xl={10} sm={12}>
              <Metrics
                metricsLoading={domainsLoading}
                metricsToShow={visibleMetrics}
                activeFilters={activeFilters}
              />
            </Col>
          </Row>
        )}
        {/* {!domainsLoading && !isEditor && (
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <BaseCard>
                <div className="text-center my-5">Not Authrorized.</div>
              </BaseCard>
            </Col>
          </Row>
        )} */}
      </Container>
    </BasePage>
  );
}

export default MetricsWrapper;
