import groupBy from "lodash/groupBy";
import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FiFilter } from "react-icons/fi";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import CheckWithCount from "../../components/CheckWithCount";
import ExpandableMenu from "../../components/ExpandableMenu";
import BasePage from "../../components/layouts/BasePage";
import { useAlertsContext } from "../../context/alerts";
import { useUserPreferencesContext } from "../../context/user-preferences";
import {
  // fetchAlerts,
  fetchDashboardDomainsAndCategories,
  fetchDashboards,
  fetchDomainAsDb,
  trackUserAction
} from "../../services/dataService";
import DashboardCategories from "./DashboardCategories";
import DashboardDomains from "./DashboardDomains";
import DashboardSearchResults from "./DashboardSearchResults";

function DashboardCatalogShell({
  globalSearchText,
  setGlobalSearchText,
  activeFilters,
  setActiveFilters
}) {
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const { addFailAlert } = useAlertsContext();
  const [openAccordionKey, setOpenAccordionKey] = useState();
  const [domainsLoading, setDomainsLoading] = useState(false);
  const [allDomains, setAllDomains] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});
  const [allDashboards, setAllDashboards] = useState([]);
  const [domainsAsDashboards, setDomainsAsDashboards] = useState([]);
  const [visibleDashboards, setVisibleDashboards] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [domainCounts, setDomainCounts] = useState([]);
  const [categoryCounts, setCategoryCounts] = useState([]);
  const [brandCounts, setBrandCounts] = useState([]);
  const [categoriesWithHelpLinks, setCategoriesWithHelpLinks] = useState([]);

  useEffect(() => {
    setDomainsLoading(true);
    fetchAllDashboardData();
  }, [addFailAlert]);

  const fetchAllDashboardData = () => {
    Promise.all([fetchDashboardDomainsAndCategories(), fetchDashboards()])
      .then(response => {
        let domainsArray = response[0].Domains;

        setCategoriesWithHelpLinks(
          domainsArray.map(el => {
            return {
              ...el,
              Help_Links: !!el.Help_Links
                ? JSON.parse(el.Help_Links.replaceAll(/'\'/g, "-"))
                : ""
            };
          })
        );

        fetchDomainAsDb()
          .then(res => {
            let domainsAsDbResponse = [];
            if (res.data.length) {
              domainsAsDbResponse = res.data;
              const DOMAINS_AS_DB_ARRAY = domainsAsDbResponse.map(d => {
                return {
                  TypeID: 99999,
                  NAME: d.dbName,
                  SortOrder: 0,
                  dbType: d.dbType,
                  Image: d.dbIcon,
                  Description: d.dbDesc,
                  Domain_ID: -1,
                  DBCount: 1
                };
              });
              domainsArray = [...DOMAINS_AS_DB_ARRAY, ...domainsArray];
            }
            setDomainsAsDashboards(domainsAsDbResponse);
            setAllDomains(
              domainsArray.sort((a, b) => a.SortOrder - b.SortOrder)
            );
            setDomainCounts(
              domainsArray.map(i => ({
                id: i.TypeID,
                count: i.DBCount,
                label: i.NAME,
                dbKeyName: "dbDomainID"
              }))
            );
            setDomainsLoading(false);
          })
          .catch(err => setDomainsLoading(false));
        setAllDomains(domainsArray.sort((a, b) => a.SortOrder - b.SortOrder));
        setDomainCounts(
          domainsArray.map(i => ({
            id: i.TypeID,
            count: i.DBCount,
            label: i.NAME,
            dbKeyName: "dbDomainID"
          }))
        );

        setCategoryCounts(
          response[0].Categories.map(i => ({
            id: i.TypeID,
            count: i.DBCount,
            label: i.NAME,
            dbKeyName: "dbCategoryID"
          }))
        );

        setBrandCounts(
          response[0].Brands.map(i => ({
            id: i.TypeID,
            count: i.DBCount,
            label: i.NAME,
            dbKeyName: "dbBrandID"
          }))
        );

        setAllCategories(
          response[0].Categories.map(c => {
            return { ...c, authorized: c.Authorized === "True" };
          })
        );
        setAllDashboards(response[1].data.filter(i => i.ObjId !== -1));
      })
      .catch(err => {
        console.log(err);
        addFailAlert("Something went wrong fetching dashboards");
        setDomainsLoading(false);
      });
  };

  useEffect(() => {
    const activeBusinessDomain =
      activeFilters.find(i => i.type === "Business Domain") || {};
    const activeDataCategory =
      activeFilters.find(i => i.type === "Subdomain") || {};

    setActiveCategory(
      allCategories.find(i => i.TypeID === activeDataCategory.id) || {}
    );
    setVisibleCategories(
      allCategories
        .filter(c => c.Domain_ID === activeBusinessDomain.id)
        .sort((a, b) => a.SortOrder - b.SortOrder)
    );

    // First, filter the superset down by text search query
    const dashboardsBySearchText = allDashboards
      .map(c => {
        return {
          ...c,
          authorized: allCategories.filter(a => a.TypeID === c.dbCategoryID)[0]
            .authorized
        };
      })
      .filter(j => {
        if (globalSearchText) {
          const stringToCheck = `${j.dbSource}${j.dbDesc}${j.dbName}${j.dbTags}`;
          return (
            stringToCheck
              .toLowerCase()
              .indexOf(globalSearchText.toLowerCase()) > -1
          );
        }
        return true;
      });

    // Break active filters out by type
    // i.e. {"Business Domain": [1234, 5678], "Subdomain": [9548, 3728]}
    const grouped = groupBy(activeFilters, "type");

    // Filter the previous result by only those who have one of the selected tags
    const appliedTags = grouped["Tags"];
    const dashboardsByTags = dashboardsBySearchText.filter(db => {
      if (!appliedTags) {
        return true;
      }
      let hasTag = false;
      appliedTags.forEach(tag => {
        if (
          !hasTag &&
          db.dbTags &&
          db.dbTags.toLowerCase().indexOf(tag.id) > -1
        ) {
          hasTag = true;
        }
      });
      return hasTag;
    });

    // Second, filter the above result by domainId
    const appliedBusinessDomains = grouped["Business Domain"];
    const dashboardsByDomain = dashboardsByTags.filter(i => {
      if (!appliedBusinessDomains) {
        return true;
      }
      return appliedBusinessDomains.map(j => j.id).indexOf(i.dbDomainID) > -1;
    });

    // Third, filter the above result by category (aka subdomain)
    const appliedSubdomains = grouped["Subdomain"];
    const dashboardsByCategory = dashboardsByDomain.filter(i => {
      if (!appliedSubdomains) {
        return true;
      }
      return appliedSubdomains.map(j => j.id).indexOf(i.dbCategoryID) > -1;
    });

    // Fourth, filter the above result by brands
    const appliedBrands = grouped["Brands"];
    const dashboardsByBrand = dashboardsByCategory.filter(i => {
      if (!appliedBrands) {
        return true;
      }
      return appliedBrands.map(j => j.id).indexOf(i.dbBrandID) > -1;
    });

    // Use the db's filtered by search text to get counts by domainId
    const countsByDomain = dashboardsByTags.reduce((acc, curr) => {
      if (!acc[curr.dbDomainID]) {
        acc[curr.dbDomainID] = 0;
      }
      acc[curr.dbDomainID]++;
      return acc;
    }, {});

    // Use the db's filtered by domain to get counts by category (aka subdomain)
    const countsByCategory = dashboardsByDomain.reduce((acc, curr) => {
      if (!acc[curr.dbCategoryID]) {
        acc[curr.dbCategoryID] = 0;
      }
      acc[curr.dbCategoryID]++;
      return acc;
    }, {});

    // Use the db's filtered by category to get counts by brand
    const countsByBrand = dashboardsByCategory.reduce((acc, curr) => {
      if (!acc[curr.dbBrandID]) {
        acc[curr.dbBrandID] = 0;
      }
      acc[curr.dbBrandID]++;
      return acc;
    }, {});

    setVisibleDashboards(dashboardsByBrand);

    setDomainCounts(domCounts =>
      domCounts.map(i => ({
        ...i,
        count: countsByDomain[i.id] || 0
      }))
    );

    setCategoryCounts(catCounts =>
      catCounts.map(i => ({
        ...i,
        count: countsByCategory[i.id] || 0
      }))
    );

    setBrandCounts(brandCounts =>
      brandCounts.map(i => ({
        ...i,
        count: countsByBrand[i.id] || 0
      }))
    );
  }, [
    allCategories,
    allDomains,
    allDashboards,
    activeFilters,
    globalSearchText
  ]);

  const sideFilterData = [
    {
      name: "Business Domain",
      id: 1,
      counts: domainCounts
    },
    { name: "Subdomain", id: 2, counts: categoryCounts },
    { name: "Brands", id: 3, counts: brandCounts }
  ];

  const handleTagClick = useCallback(
    (tag, path) => {
      if (location.pathname.indexOf("categories") > -1) {
        history.push("/dashboardcatalog/search");
      }

      trackUserAction({
        action: "Search",
        targetType: "tag",
        targetName: tag,
        targetPath: path,
        targetID: "",
        status: "Success",
        errorDetails: ""
      });

      setActiveFilters(af => {
        if (
          af.find(
            f =>
              f.type === "Tags" && f.value.toLowerCase() === tag.toLowerCase()
          )
        ) {
          return af; // Don't do anything if tag already exists
        }

        return [
          ...af,
          {
            id: tag.toLowerCase(),
            type: "Tags",
            value: tag,
            dbKeyName: "dbTags"
          }
        ];
      });
    },
    [setActiveFilters, history, location.pathname]
  );

  const {
    favorites,
    addFavorites,
    removeFavorites
  } = useUserPreferencesContext();

  const onFavToggle = useCallback(
    (isFav, dbId) => {
      isFav ? addFavorites({ dbId: dbId }) : removeFavorites({ dbId: dbId });
    },
    [addFavorites, removeFavorites]
  );

  return (
    <BasePage>
      <Container fluid className="p-4">
        <Row className="pt-4">
          <Col md={4} lg={3} xl={2} sm={12}>
            <ExpandableMenu
              title={
                <div className="d-flex align-items-center">
                  <FiFilter />
                  <span className="ml-2">Filters</span>
                </div>
              }
              onItemClick={(key, item) => {
                if (key === openAccordionKey) {
                  return setOpenAccordionKey(null);
                }
                if (item.body) {
                  return setOpenAccordionKey(key);
                }
              }}
              activeKey={openAccordionKey}
              items={sideFilterData.map(i => ({
                ...i,
                body: (
                  <div>
                    {i.counts.map(({ label, count, id, dbKeyName }) => (
                      <CheckWithCount
                        key={id}
                        className="mb-2"
                        name={`${i.name}|${id}|${label}`}
                        label={label}
                        checked={!!activeFilters.find(k => k.id === id)}
                        count={count}
                        onChange={e => {
                          trackUserAction({
                            action: "Search",
                            targetType: "filter",
                            targetName: `${i.name}|${id}|${label}`,
                            targetPath: "/dashboardcatalog/search",
                            targetID: id,
                            status: "Success",
                            errorDetails: ""
                          });
                          history.push("/dashboardcatalog/search");
                          const filterExists = activeFilters.find(
                            j => j.id === Number(id)
                          );
                          if (filterExists) {
                            return setActiveFilters(af =>
                              af.filter(j => j.id !== id)
                            );
                          }
                          return setActiveFilters(af => [
                            ...af,
                            {
                              type: i.name,
                              value: label,
                              id: id,
                              dbKeyName
                            }
                          ]);
                        }}
                      />
                    ))}
                  </div>
                )
              }))}
            ></ExpandableMenu>
          </Col>
          <Col md={8} lg={9} xl={10} sm={12}>
            <Switch>
              {/* If landing on "/dashboardcatalog" then redirect to "/dashboardcatalog/domains" */}
              <Redirect exact path={path} to={`${path}/domains`} />

              {/* Sub-Route for Domains */}
              <Route exact path={`${path}/domains`}>
                <DashboardDomains
                  categoriesWithHelpLinks={categoriesWithHelpLinks}
                  setGlobalSearchText={setGlobalSearchText}
                  setActiveFilters={setActiveFilters}
                  domains={allDomains}
                  domainsAsDashboards={domainsAsDashboards}
                  domainsLoading={domainsLoading}
                />
              </Route>

              {/* Sub-Route for Categories */}
              <Route exact path={`${path}/categories`}>
                <DashboardCategories
                  activeDomain={activeFilters.find(
                    i => i.type === "Business Domain"
                  )}
                  categoriesWithHelpLinks={categoriesWithHelpLinks}
                  onTagClick={handleTagClick}
                  setActiveFilters={setActiveFilters}
                  activeCategory={activeCategory}
                  categories={visibleCategories}
                  categoriesLoading={domainsLoading}
                  dashboards={visibleDashboards}
                  dashboardsLoading={domainsLoading}
                  favorites={favorites}
                  onFavToggle={onFavToggle}
                />
              </Route>

              {/* Sub-Route for Search Results */}
              <Route exact path={`${path}/search`}>
                <DashboardSearchResults
                  onTagClick={handleTagClick}
                  dashboardsLoading={domainsLoading}
                  dashboards={visibleDashboards}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  globalSearchText={globalSearchText}
                  favorites={favorites}
                  onFavToggle={onFavToggle}
                  fetchAllDashboardData={fetchAllDashboardData}
                />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    </BasePage>
  );
}

export default DashboardCatalogShell;
