import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { trackUserAction } from "../../services/dataService";
import { LinkContainer } from "react-router-bootstrap";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BaseCard from "../../components/BaseCard";
import DomainCard from "../../components/DomainCard";
import Spinner from "../../components/Spinner";
import Disclaimer from "./Disclaimer";
import { useAlertsContext } from "../../context/alerts";
import { launchReportInNewWindow } from "../../services/cognosService";
import { APP_VARS } from "../../services/appVars";
import { getUrlWithNoSpace } from "../../services/utils";

function DashboardDomains({
  domains,
  domainsLoading,
  setActiveFilters,
  setGlobalSearchText,
  domainsAsDashboards,
  categoriesWithHelpLinks
}) {
  const history = useHistory();

  const { activeAlerts, addCustomAlert, markAsInactive } = useAlertsContext();

  useEffect(() => {
    setActiveFilters([]);
    setGlobalSearchText("");
  }, [setActiveFilters, setGlobalSearchText]);
  return (
    <BaseCard>
      <div className="pt-1 pl-2 gray-bottom-border-1">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Dashboard Catalog</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="d-flex align-items-center pt-4 px-4 pb-2">
        <span className="text-primary font-medium">
          DASHBOARD CATALOG - Domains
        </span>
      </div>
      <Disclaimer className="pb-2 pl-4" />
      <Container fluid className="p-4">
        {domainsLoading && <Spinner>Fetching Domains</Spinner>}
        {!domainsLoading && domains.length === 0 && (
          <div className="text-center">No domains available.</div>
        )}
        {!domainsLoading && domains.length !== 0 && (
          <Row>
            {domains.map((d, index) => (
              <Col key={index} sm={12} md={6} lg={4} xl={3}>
                <DomainCard
                  onClick={() => {
                    if (domainsAsDashboards.some(d2 => d2.dbName === d.NAME)) {
                      const { dbName, dbId } = domainsAsDashboards.find(
                        d2 => d2.dbName === d.NAME
                      );
                      launchReportInNewWindow(getUrlWithNoSpace(dbName), dbId);
                    } else {
                      setActiveFilters(af => {
                        const filtered = af.filter(
                          i => i.type !== "Business Domain"
                        );
                        return [
                          ...filtered,
                          {
                            type: "Business Domain",
                            value: d.NAME,
                            id: d.TypeID,
                            dbKeyName: "dbDomainID"
                          }
                        ];
                      });
                      trackUserAction({
                        action: "View",
                        targetType: "dashboard categories",
                        targetName: d.NAME,
                        targetPath: `/dashboardcatalog/categories`,
                        targetID: d.TypeID,
                        status: "Success",
                        errorDetails: ""
                      });
                      const foundActiveAlert = activeAlerts.filter(
                        item => item.Menu === d.NAME && item.show === true
                      );
                      if (foundActiveAlert) {
                        foundActiveAlert.forEach(faa => {
                          addCustomAlert(faa.title, faa.description);
                          markAsInactive(faa.id + faa.Menu);
                        });
                      }

                      history.push(`/dashboardcatalog/categories`);
                    }
                  }}
                  popoverTitle={d["NAME"]}
                  popoverText={d["Description"]}
                  title={d["NAME"]}
                  img={
                    <img
                      src={APP_VARS.REACT_APP_ASSETS_URL + d.Image}
                      alt={d.Name}
                    />
                  }
                  className={
                    "mb-4 mr-2 " +
                    (domainsAsDashboards.some(d2 => d2.dbName === d.NAME)
                      ? "domain-as-db-card"
                      : null)
                  }
                />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </BaseCard>
  );
}

export default DashboardDomains;
