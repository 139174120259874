import { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { APP_VARS } from "../services/appVars";
import {
  fetchAuthToken,
  fetchUserInfo,
  isExecUser
} from "../services/dataService";
import axios from "axios";
import jwt_decode from "jwt-decode";

const isExpired = token => {
  const decoded = jwt_decode(token);
  return decoded.exp * 1000 <= Date.now();
};

// When sending query params to cognito/one-login
// must use "+" instead of "&" because the URL gets
// confused having multiple &'s
function serializeQueryParams(params) {
  return params.split("&").join("+");
}

// When coginito/one-login return the state parameter
// it comes as an encoded, space delimited string.
// It must be decoded, and turned back into a standard query string
function deserializeQueryParams(params) {
  return decodeURIComponent(params)
    .split(" ")
    .join("&");
}

function useAuthManager() {
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [isExecutiveUser, setIsExecUser] = useState("");
  const location = useLocation();
  const currentPathName = `${location.pathname}${location.search || ""}`;

  const history = useHistory();

  const clearData = () => {
    localStorage.removeItem("ID_TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
    localStorage.removeItem("REFRESH_EXP_TIME");
    setUserName("");
  };

  const getTokensByRefreshToken = useCallback(token => {
    let authPayload = new URLSearchParams();
    authPayload.append("grant_type", "refresh_token");
    authPayload.append("client_id", APP_VARS.REACT_APP_COGNITO_CLIENT_ID);
    authPayload.append("refresh_token", token);
    return fetchAuthToken(authPayload).then(res => {
      axios.defaults.headers.common["Authorization"] = res.data.id_token;
      localStorage.setItem("ID_TOKEN", res.data.id_token);
    });
  }, []);

  useEffect(() => {
    const ID_TOKEN = localStorage.getItem("ID_TOKEN");
    const searchParams = new URLSearchParams(location.search);
    const CODE = searchParams.get("code");
    const REFRESH_TOKEN = localStorage.getItem("REFRESH_TOKEN");

    const getTokensByCode = code => {
      let authPayload = new URLSearchParams();
      authPayload.append("code", code);
      authPayload.append("client_id", APP_VARS.REACT_APP_COGNITO_CLIENT_ID);
      authPayload.append("grant_type", "authorization_code");
      authPayload.append("redirect_uri", encodeURI(window.location.origin));
      fetchAuthToken(authPayload)
        .then(res => {
          localStorage.setItem("REFRESH_TOKEN", res.data.refresh_token);
          localStorage.setItem("ID_TOKEN", res.data.id_token);
          localStorage.setItem(
            "REFRESH_EXP_TIME",
            Date.now() + 29 * 24 * 60 * 60 * 1000
          );
          axios.defaults.headers.common["Authorization"] = res.data.id_token;
          const state = deserializeQueryParams(searchParams.get("state"));
          history.push(state);
          fetchUserInfo().then(res => {
            setUserName(res.data.firstName + " " + res.data.lastName);

            isExecUser()
              .then(resp => {
                setIsLoading(false);
                setIsExecUser(resp.data);
              })
              .catch(err => {
                console.log(err);
                setIsLoading(false);
              });
          });
        })
        .catch(err => {
          clearData();
          setIsLoading(false);
          console.log(err);
        });
    };

    //is redirected user
    if (CODE) {
      getTokensByCode(CODE);
      return;
    }

    //is long returning user
    if (hasValidRefreshToken() && !hasValidIdToken()) {
      getTokensByRefreshToken(REFRESH_TOKEN)
        .then(res => {
          fetchUserInfo().then(res => {
            setUserName(res.data.firstName + " " + res.data.lastName);

            isExecUser()
              .then(resp => {
                setIsLoading(false);
                setIsExecUser(resp.data);
              })
              .catch(err => {
                console.log(err);
                setIsLoading(false);
              });
          });
        })
        .catch(err => {
          clearData();
          setIsLoading(false);
          console.log(err);
        });
      return;
    }

    //is short returning user
    if (hasValidRefreshToken() && hasValidIdToken()) {
      if (!axios.defaults.headers.common["Authorization"]) {
        axios.defaults.headers.common["Authorization"] = ID_TOKEN;
      }
      if (!userName) {
        fetchUserInfo()
          .then(res => {
            setUserName(res.data.firstName + " " + res.data.lastName);

            isExecUser()
              .then(resp => {
                setIsLoading(false);
                setIsExecUser(resp.data);
              })
              .catch(err => {
                console.log(err);
                setIsLoading(false);
              });
          })
          .catch(() => {
            clearData();
            setIsLoading(false);
          });
      }

      return;
    }

    clearData();

    window.location = `${APP_VARS.REACT_APP_COGNITO_BASE_URL}${
      window.location.origin
    }&state=${serializeQueryParams(currentPathName)}`;
    return;
  }, [
    currentPathName,

    getTokensByRefreshToken,
    history,
    location.search,
    userName
  ]);

  return { userName, isLoading, getTokensByRefreshToken, isExecutiveUser };
}

export default useAuthManager;

function hasValidRefreshToken() {
  const token = localStorage.getItem("REFRESH_TOKEN");
  const expTime = localStorage.getItem("REFRESH_EXP_TIME");
  return token && expTime && expTime - Date.now() > 7 * 24 * 60 * 60 * 1000;
}

function hasValidIdToken() {
  const idToken = localStorage.getItem("ID_TOKEN");
  return idToken && !isExpired(idToken);
}
